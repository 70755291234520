import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import React, { useEffect, useRef, useState } from "react";
// @ts-ignore
import {NotificationContainer, NotificationManager} from "react-notifications";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import Web3 from "web3";
import Button from "../../components/Base/Button";
import ButtonLink from "../../components/Base/ButtonLink";
import CountdownWhite from "../../components/Base/CountdownWhite";
import FooterLandingLayout from "../../components/Base/FooterLandingLayout";
import MainDefaultLayout from "../../components/Base/MainDefaultLayout";
import { minABI } from "../../constants/basicABI";
import { jackpotABI } from "../../constants/jackpotABI";
import { useCommonStyle } from "../../styles";
import useStyles from "./style";
import Winner from "./Winner";

// @ts-ignore
const web3 = new Web3(process.env.REACT_APP_RPC_URL);
const iconGoto = "/images/icons/icon-goto.svg";
const logo = "/images/logo-text.svg";
const joinStep = "/images/join-step.svg";
const coinLeft = "/images/left-coin-bg.png";
const coinRight = "/images/right-coin-bg.png";
const underBuyTicket = "/images/buyticket-underline.png";
const youtute = "/images/redkite-banner-youtube.png";
const medium = "/images/redkite-medium-banner.png";
const twitter = "/images/redkite-banner-twitter.png";
const whoCanJoinText = "/images/who-can-join-text.png";
const REGEX_NUMBER = /^-?[0-9]{0,}[.]{0,1}[0-9]{0,6}$/;

// @ts-ignore
const gafiContract = new web3.eth.Contract(
  minABI,
  process.env.REACT_APP_GAFI_CONTRACT
);
// @ts-ignore
const jackpotContract = new web3.eth.Contract(
  jackpotABI,
  process.env.REACT_APP_JACKPOT_CONTRACT
);

const EventJackpot = (props: any) => {
  const styles = useStyles();
  const commonStyle = useCommonStyle();
  const buyTicketRef = useRef<null | HTMLDivElement>(null);
  const rewardsRef = useRef<null | HTMLDivElement>(null);
  const joinRef = useRef<null | HTMLDivElement>(null);

  const [ticketAmountStep, setTicketAmountStep] = useState("");
  const [address, setAddress] = useState(
    localStorage.getItem("CONNECTED_WALLET")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [ticketBalance, setTicketBalance] = useState(0);
  const [ticketUsed, setTicketUsed] = useState(0);
  const [currentUsed, setCurrentUsed] = useState(0);
  const [currentRound, setCurrentRound] = useState(0);
  const [isApproved, setIsApproved] = useState(false);
  const [roundIsEnded, setRoundIsEnded] = useState(false);
  const [eventIsEnded, setEventIsEnded] = useState(false);
  const [isPublicWinner, setIsPublicWinner] = useState(false);
  const [reward, setReward] = useState(0);
  const [ticketAmount, setTicketAmount] = useState("");
  const [gameFiBalance, setGameFiBalance] = useState(0);
  const [wrongChain, setWrongChain] = useState(false);
  const [poolPrize, setPoolPrize] = useState(0);
  const [currentRoundLabel, setCurrentRoundLabel] = useState("1ST");
  const [nextRoundLabel, setNextRoundLabel] = useState("2ND");
  const [nextRoundStart, setNextRoundStart] = useState(0);
  const { active, account, library, connector, activate, deactivate } =
    useWeb3React();

  useEffect(() => {
    //window.scroll({ top: 0 });
  }, []);

  useEffect(() => {
    calCurrentRoundLabel();
  },[currentRound])

  useEffect(() => {
    //   setTimeout(function(){ window.scroll({ top: 0, behavior: 'smooth'}) }, 500);
    if (address) {
      handleConnect();
    }
    if (account) {
      setAddress(account);
      localStorage.setItem("CONNECTED_WALLET", account);
      loadBalance();
    }
    loadRound();
    checkValidChainNetwork();
    forceChain();
  }, [account]);
  // @ts-ignore

  const forceChain = async () => {
    const provider = await connector?.getProvider();
    if (!provider) {
      return;
    }
    try {
      await checkChainNetwork(provider);
    } catch (e) {
      console.log(e);
    }
  };

  const checkChainNetwork = async (provider: any) => {
    console.log(provider.chainId);
    console.log(process.env.REACT_APP_NODE_ENV);
    if (
      process.env.REACT_APP_NODE_ENV === "production" &&
      provider.chainId === "0x38"
    ) {
      return;
    }
    if (
      process.env.REACT_APP_NODE_ENV !== "production" &&
      provider.chainId === "0x61"
    ) {
      return;
    }
    let chainID = "0x61";
    let rpc = "https://data-seed-prebsc-1-s1.binance.org:8545";
    let chain = "Binance Smart Chain Testnet";
    let scanURL = "https://testnet.bscscan.com/";
    if (process.env.REACT_APP_NODE_ENV === "production") {
      chainID = "0x38";
      rpc = "https://bsc-dataseed.binance.org/";
      chain = "Binance Smart Chain";
      scanURL = "https://bscscan.com/";
    }
    try {
      // @ts-ignore
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainID }],
      });
    } catch (error) {
      console.log(error);
      // @ts-ignore
      if (error.code === 4902) {
        try {
          // @ts-ignore
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chainID,
                chainName: chain,
                rpcUrls: [rpc],
                nativeCurrency: {
                  name: "BNB",
                  symbol: "BNB",
                  decimals: 18,
                },
                blockExplorerUrls: [scanURL],
              },
            ],
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const checkValidChainNetwork = () => {
    connector?.getProvider().then((provider) => {
      if (!provider) {
        return;
      }
      if (
        (process.env.REACT_APP_NODE_ENV === "production" &&
          provider.chainId === "0x38") ||
        (process.env.REACT_APP_NODE_ENV !== "production" &&
          provider.chainId === "0x61")
      ) {
        setWrongChain(false);
      } else {
        setWrongChain(true);
      }
    });
  };

  // @ts-ignore
  if (!!window.ethereum) {
    // @ts-ignore
    window.ethereum.on("networkChanged", checkValidChainNetwork);
  }

  const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 42, 56, 97],
  });

  const handleConfirm = async () => {
    if (!account) {
      NotificationManager.error(
        "Please connect your wallet first and get Lucky Tickets to join."
      );
      return;
    }
    if (wrongChain) {
      NotificationManager.error(
        "App network (Binance Smart Chain) doesn't mach to network."
      );
      return;
    }
    if (roundIsEnded) {
      NotificationManager.error("This Round Has Ended");
      return;
    }

    if (Number(ticketAmountStep) <= 0) {
      NotificationManager.error(
        "You need enter number of Lucky Tickets to join."
      );
      return;
    }

    if (Number(ticketAmountStep) > ticketBalance) {
      NotificationManager.error("You need to get more Lucky Tickets to join.");
      return;
    }

    setIsConfirming(true);
    const tx = {
      from: account,
      to: process.env.REACT_APP_JACKPOT_CONTRACT,
      data: jackpotContract.methods
        .useTicket(currentRound, ticketAmountStep)
        .encodeABI(),
    };
    try {
      // @ts-ignore
      const transactonHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [tx],
      });
      let transactionReceipt: any = null;
      while (transactionReceipt == null) {
        // Waiting expectedBlockTime until the transaction is mined
        transactionReceipt = await web3.eth.getTransactionReceipt(
          transactonHash
        );
        await sleep(3000);
      }
      if (transactionReceipt.status) {
        loadBalance();
        NotificationManager.success("Use tickets successfully");
      } else {
        NotificationManager.error("Transaction failed");
      }
      setIsConfirming(false);
    } catch (e) {
      NotificationManager.error("User canceled");
      setIsConfirming(false);
    }
  };

  const loadBalance = async () => {
    if (!account) {
      return;
    }
    const gamefi_balance = await gafiContract.methods.balanceOf(account).call();
    setGameFiBalance(parseFloat(web3.utils.fromWei(gamefi_balance)));

    const balance = await jackpotContract.methods.users(account).call();
    setTicketBalance(balance?.purchased);
    setTicketUsed(balance?.used);
    const approveAmount = await gafiContract.methods
      .allowance(account, process.env.REACT_APP_JACKPOT_CONTRACT)
      .call();
    setIsApproved(approveAmount > 0);
    const winnerData = await jackpotContract.methods.winners(account).call();
    setReward(parseInt(winnerData[`round_${currentRound}`]));
    const usedTicket = await jackpotContract.methods
      .used_tickets(account)
      .call();
    setCurrentUsed(usedTicket[`${currentRound - 1}`]);
  };

  const loadRound = async () => {
    let currentR = await jackpotContract.methods.currentRound().call();
    let cRound = parseInt(currentR);
    setCurrentRound(cRound);
    console.log(currentR)
    const roundData = await jackpotContract.methods.rounds(cRound).call();
    console.log(roundData)
    setIsPublicWinner(roundData?.public_winner);
    setRoundIsEnded(new Date().getTime() > parseInt(roundData?.end) * 1000);
    let prize = await jackpotContract.methods.token_pool_prize().call();
    setPoolPrize(parseFloat(web3.utils.fromWei(prize)));
    if (cRound < 3) {
      const nextRoundData = await jackpotContract.methods
        .rounds(cRound + 1)
        .call();
      setNextRoundStart(parseInt(nextRoundData?.start) * 1000);
    } else {
      const nextRoundData = await jackpotContract.methods.rounds(3).call();
      setNextRoundStart(parseInt(nextRoundData?.end) * 1000);
      setEventIsEnded(
        parseInt(nextRoundData?.end) * 1000 < new Date().getTime()
      );
    }
  };

  const handleConnect = async () => {
    try {
      // @ts-ignore
      if (!window.ethereum) {
        NotificationManager.error("MetaMask is not installed");
        return;
      }
      await activate(injected);
    } catch (ex) {
      console.log(ex);
    }
  };
  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const handlePurchase = async () => {
    if (!account) {
      NotificationManager.error(
        "Please connect your wallet first and get Lucky Tickets to purchase."
      );
      return;
    }
    if (wrongChain) {
      NotificationManager.error(
        "App network (Binance Smart Chain) doesn't mach to network."
      );
      return;
    }
    if (eventIsEnded) {
      NotificationManager.error("The Event Has Ended");
      return;
    }

    if (Number(ticketAmount) <= 0) {
      NotificationManager.error(
        "You need enter number of Lucky Tickets to purchase."
      );
      return;
    }
    setIsLoading(true);
    const tx = {
      from: account,
      to: process.env.REACT_APP_JACKPOT_CONTRACT,
      data: jackpotContract.methods.buyTicket(Number(ticketAmount)).encodeABI(),
    };
    try {
      // @ts-ignore
      const transactonHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [tx],
      });
      let transactionReceipt: any = null;
      while (transactionReceipt == null) {
        // Waiting expectedBlockTime until the transaction is mined
        transactionReceipt = await web3.eth.getTransactionReceipt(
          transactonHash
        );
        await sleep(3000);
      }
      if (transactionReceipt.status) {
        loadBalance();
        NotificationManager.success("Buy successfully");
      } else {
        NotificationManager.error("Transaction failed");
      }
      setIsLoading(false);
    } catch (e) {
      NotificationManager.error("User canceled");
      setIsLoading(false);
    }
  };

  const handleApprove = async () => {
    if (!account) {
      NotificationManager.error(
        "Please connect your wallet first and get Lucky Tickets to join."
      );
      return;
    }
    if (wrongChain) {
      NotificationManager.error(
        "App network (Binance Smart Chain) doesn't mach to network."
      );
      return;
    }
    setIsLoading(true);
    const tx = {
      from: account,
      to: process.env.REACT_APP_GAFI_CONTRACT,
      data: gafiContract.methods
        .approve(
          process.env.REACT_APP_JACKPOT_CONTRACT,
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
        )
        .encodeABI(),
    };
    try {
      // @ts-ignore
      const transactonHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [tx],
      });
      let transactionReceipt: any = null;
      while (transactionReceipt == null) {
        // Waiting expectedBlockTime until the transaction is mined
        transactionReceipt = await web3.eth.getTransactionReceipt(
          transactonHash
        );
        await sleep(3000);
      }
      if (transactionReceipt.status) {
        loadBalance();
        NotificationManager.success("Approve token successfully");
      } else {
        NotificationManager.error("Approve token failed");
      }
      setIsLoading(false);
    } catch (e) {
      NotificationManager.error("User canceled");
      setIsLoading(false);
    }
  };

  const handleDisconnect = async () => {
    try {
      setAddress(null);
      localStorage.removeItem("CONNECTED_WALLET");
      await deactivate();
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeTicketAmount = async (e: any) => {
    const value = e.target.value.replaceAll(",", "");
    if (value === "" || REGEX_NUMBER.test(value)) {
      setTicketAmount(value);
    }
  };

  const handleBuyTickets = () => {
    alert("buy tickets");
  };

  const calCurrentRoundLabel = () => {
    console.log(currentRound)
    switch (currentRound) {
      case 1:
        setCurrentRoundLabel("1ST");
        setNextRoundLabel("2ND");
        return;
      case 2:
        setCurrentRoundLabel("2ND");
        setNextRoundLabel("LAST");
        return;
      case 3:
        setCurrentRoundLabel("LAST");
        setNextRoundLabel("ENDED");
        return;
    }
  };

  const handleChangeTicketAmountStep = async (e: any) => {
    const value = e.target.value.replaceAll(",", "");
    if (value === "" || REGEX_NUMBER.test(value)) {
      setTicketAmountStep(value);
    }
  };

  const renderBanner = () => {
    return (
      <div className={styles.banner}>
        {wrongChain && (
          <p className={styles.wrongChainAlert}>
            App network (
            {process.env.REACT_APP_NODE_ENV === "production"
              ? "Binance Smart Chain"
              : "Binance Smart Chain testnet"}
            ) doesn't mach to network selected in wallet: BSC Testnet. Learn how
            to{" "}
            <a
              href={
                "https://help.1inch.exchange/en/articles/4966690-how-to-use-1inch-on-bsc-binance-smart-chain"
              }
            >
              change network in wallet
            </a>
          </p>
        )}
        <div className="text-banner">
          <img className={styles.coinLeftBG} src={coinLeft} />
          <img className={styles.coinRightBG} src={coinRight} />
          <span className="left">Gamefi</span>
          <div style={{ display: "flex" }}>
            <span>Lucky </span>
            <span>tickets</span>
          </div>
        </div>
        <div className={styles.groupContainer}>
          <div className="text-left">
            We know how much our community love play-to-earn NFT games, then you
            cannot miss our Lucky Ticket minigame to celebrate Icetea Labs's 3rd
            anniversary!
          </div>
          <div className={styles.flexCol}>
            <div className="about">
              <div className="special">🔥 SPECIAL PRIZE 🔥</div>
              <span className="content">
                All the $GAFI from bought tickets throughout the game
              </span>
            </div>
            <div className={styles.gafiPrize}>
              <div className="prize-left">
                <span className="text-prize">
                  3 draws with a total prize of{" "}
                </span>
                <span className="prize-number">$9,000</span>
              </div>
              <div className="prize-right">
                <span className="text-prize">
                  Join event by getting a ticket of
                </span>
                <div style={{ marginTop: 10 }}>
                  <span className="prize-number">0.005</span>
                  <span className="prize-currency">$GAFI</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBuyTicketStep = () => {
    // @ts-ignore
    return (
      <div className={styles.buyTicketStepContainer} ref={rewardsRef}>
        <div className={styles.buyTicketTimeline}>
          <div className={styles.buyTicketTimelineTitle}>
            <span>Save the date</span>
          </div>
          <div className={styles.timelineDrawContainer}>
            <div
              className={
                currentRound == 1
                  ? styles.timelineTickActive
                  : styles.timelineTick
              }
            ></div>
            {[...Array(14)].map((e, i) => {
              return <div key={i} className={styles.timelineSmallTick}></div>;
            })}
            <div
              className={
                currentRound == 2
                  ? styles.timelineTickActive
                  : styles.timelineTick
              }
            ></div>
            {[...Array(14)].map((e, i) => {
              return <div key={i} className={styles.timelineSmallTick}></div>;
            })}
            <div
              className={
                currentRound == 3
                  ? styles.timelineTickActive
                  : styles.timelineTick
              }
            ></div>
            {[...Array(14)].map((e, i) => {
              return <div key={i} className={styles.timelineSmallTick}></div>;
            })}
          </div>
          <div className={styles.timelineDrawContainer}>
            <div className={styles.timelineText}>
              <span
                className={currentRound === 1 ? styles.timelineLabelActive : ""}
              >
                1st DRAW
              </span>
              <h3>$3,000</h3>
              <span>27 Oct 2021</span>
            </div>
            <div className={styles.timelineText}>
              <span
                className={currentRound === 2 ? styles.timelineLabelActive : ""}
              >
                2nd DRAW
              </span>
              <h3>$3,000</h3>
              <span>29 Oct 2021</span>
            </div>
            <div className={styles.timelineText}>
              <span
                className={currentRound === 3 ? styles.timelineLabelActive : ""}
              >
                LAST DRAW
              </span>
              <h3 style={{ fontSize: 28 }}>$3,000 + All $GAFI bought</h3>
              <span>01 Nov 2021</span>
            </div>
          </div>
        </div>
        {currentRound >= 1 && !roundIsEnded && (
          <div className={styles.buyTicketStep}>
            <span className="title-header">
              🔥 {currentRoundLabel} DRAW IS NOW OPEN 🔥
            </span>
            <div className={styles.flexRow}>
              {currentRound === 1 && (
                <div className={`left ${styles.flexCol}`}>
                  <span className="text-step-left">1ST DRAW REWARD</span>
                  <span className="total-reward">$3000</span>
                  <span className="text-step-left">REWARD DETAILS</span>
                  <span className="text-step-left">
                    <ul
                      style={{
                        listStyleType: "disc",
                        marginLeft: 20,
                        marginBottom: 20,
                      }}
                    >
                      <li>100 prizes, each worth $10 </li>
                      <li>50 prizes, each worth $20 </li>
                      <li>25 prizes, each worth $40</li>
                    </ul>
                    <a
                      href={
                        "https://medium.com/gamefi-official/celebrating-icetea-labs-3rd-anniversary-with-special-prizes-worth-12-000-d53cf7537463"
                      }
                      target={"_blank"}
                      style={{ color: "#4CCDFF", marginTop: 20 }}
                    >
                      {"VIEW RULE DETAILS"}{" "}
                      <svg
                        width="14"
                        height="13"
                        viewBox="0 0 14 13"
                        fill="#4CCDFF"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.03597 6.04524C4.02063 8.05851 2.02274 10.054 0.0068026 12.0676C0.074237 12.0676 0.121264 12.0676 0.16829 12.0676C0.897647 12.0676 1.627 12.0655 2.35636 12.07C2.45929 12.0706 2.52968 12.0377 2.60066 11.9667C4.51515 10.0493 6.43111 8.13363 8.34649 6.21767C8.50975 6.0544 8.51064 6.01566 8.35152 5.85654C6.42816 3.93288 4.50509 2.00893 2.57848 0.0882329C2.52997 0.0397275 2.4442 0.0048272 2.37558 0.00423567C1.63144 -0.00167963 0.887295 0.000390726 0.14315 0.000982256C0.102039 0.000982256 0.0612234 0.00689756 0 0.011334C2.01357 2.02402 4.00969 4.01954 6.03597 6.04524ZM11.5686 6.06032C9.55853 8.06827 7.56123 10.0638 5.56097 12.0617C5.57754 12.0635 5.59587 12.0673 5.61421 12.0673C6.38793 12.0676 7.16165 12.0694 7.93537 12.0641C7.99453 12.0638 8.0664 12.025 8.10988 11.9815C10.0386 10.0579 11.9646 8.13156 13.8906 6.20525C14.0364 6.05943 14.0367 6.01122 13.89 5.86452C11.9634 3.93732 10.0368 2.00982 8.10692 0.0852753C8.06108 0.0397275 7.98151 0.00453144 7.91763 0.00393991C7.16372 -0.00167963 6.40952 9.49614e-05 5.65562 0.000982256C5.62042 0.000982256 5.58523 0.00837638 5.52252 0.0157705C7.53935 2.032 9.53576 4.02782 11.5686 6.06032Z"
                          fill="#4CCDFF"
                        />
                      </svg>
                    </a>
                  </span>
                </div>
              )}
              {currentRound === 2 && (
                <div className={`left ${styles.flexCol}`}>
                  <span className="text-step-left">2ND DRAW REWARD</span>
                  <span className="total-reward">$3000</span>
                  <span className="text-step-left">REWARD DETAILS</span>
                  <span className="text-step-left">
                    <ul
                      style={{
                        listStyleType: "disc",
                        marginLeft: 20,
                        marginBottom: 20,
                      }}
                    >
                      <li>50 prizes, each worth $10</li>
                      <li>50 prizes, each worth $20</li>
                      <li>25 prizes, each worth $40</li>
                      <li>10 prizes, each worth $50</li>
                    </ul>
                    <a
                      href={
                        "https://medium.com/gamefi-official/celebrating-icetea-labs-3rd-anniversary-with-special-prizes-worth-12-000-d53cf7537463"
                      }
                      target={"_blank"}
                      style={{ color: "#4CCDFF", marginTop: 20 }}
                    >
                      {"VIEW RULE DETAILS"}{" "}
                      <svg
                        width="14"
                        height="13"
                        viewBox="0 0 14 13"
                        fill="#4CCDFF"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.03597 6.04524C4.02063 8.05851 2.02274 10.054 0.0068026 12.0676C0.074237 12.0676 0.121264 12.0676 0.16829 12.0676C0.897647 12.0676 1.627 12.0655 2.35636 12.07C2.45929 12.0706 2.52968 12.0377 2.60066 11.9667C4.51515 10.0493 6.43111 8.13363 8.34649 6.21767C8.50975 6.0544 8.51064 6.01566 8.35152 5.85654C6.42816 3.93288 4.50509 2.00893 2.57848 0.0882329C2.52997 0.0397275 2.4442 0.0048272 2.37558 0.00423567C1.63144 -0.00167963 0.887295 0.000390726 0.14315 0.000982256C0.102039 0.000982256 0.0612234 0.00689756 0 0.011334C2.01357 2.02402 4.00969 4.01954 6.03597 6.04524ZM11.5686 6.06032C9.55853 8.06827 7.56123 10.0638 5.56097 12.0617C5.57754 12.0635 5.59587 12.0673 5.61421 12.0673C6.38793 12.0676 7.16165 12.0694 7.93537 12.0641C7.99453 12.0638 8.0664 12.025 8.10988 11.9815C10.0386 10.0579 11.9646 8.13156 13.8906 6.20525C14.0364 6.05943 14.0367 6.01122 13.89 5.86452C11.9634 3.93732 10.0368 2.00982 8.10692 0.0852753C8.06108 0.0397275 7.98151 0.00453144 7.91763 0.00393991C7.16372 -0.00167963 6.40952 9.49614e-05 5.65562 0.000982256C5.62042 0.000982256 5.58523 0.00837638 5.52252 0.0157705C7.53935 2.032 9.53576 4.02782 11.5686 6.06032Z"
                          fill="#4CCDFF"
                        />
                      </svg>
                    </a>
                  </span>
                </div>
              )}
              {currentRound === 3 && (
                <div className={`left ${styles.flexCol}`}>
                  <span className="text-step-left">LAST DRAW REWARD</span>
                  <span className="total-reward">
                    $3,000 + All $GAFI bought
                  </span>
                  <span className="text-step-left">REWARD DETAILS</span>
                  <span className="text-step-left">
                    <ul
                      style={{
                        listStyleType: "disc",
                        marginLeft: 20,
                        marginBottom: 20,
                      }}
                    >
                      <li>50 prizes, each worth $10</li>
                        <li>25 prizes, each worth $20</li>
                        <li>25 prizes, each worth $40 </li>
                        <li>10 prizes, each worth $50</li>
                        <li>5 prizes, each worth $100</li>
                    </ul>
                    <a
                      href={
                        "https://medium.com/gamefi-official/celebrating-icetea-labs-3rd-anniversary-with-special-prizes-worth-12-000-d53cf7537463"
                      }
                      target={"_blank"}
                      style={{ color: "#4CCDFF", marginTop: 20 }}
                    >
                      {"VIEW RULE DETAILS"}{" "}
                      <svg
                        width="14"
                        height="13"
                        viewBox="0 0 14 13"
                        fill="#4CCDFF"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.03597 6.04524C4.02063 8.05851 2.02274 10.054 0.0068026 12.0676C0.074237 12.0676 0.121264 12.0676 0.16829 12.0676C0.897647 12.0676 1.627 12.0655 2.35636 12.07C2.45929 12.0706 2.52968 12.0377 2.60066 11.9667C4.51515 10.0493 6.43111 8.13363 8.34649 6.21767C8.50975 6.0544 8.51064 6.01566 8.35152 5.85654C6.42816 3.93288 4.50509 2.00893 2.57848 0.0882329C2.52997 0.0397275 2.4442 0.0048272 2.37558 0.00423567C1.63144 -0.00167963 0.887295 0.000390726 0.14315 0.000982256C0.102039 0.000982256 0.0612234 0.00689756 0 0.011334C2.01357 2.02402 4.00969 4.01954 6.03597 6.04524ZM11.5686 6.06032C9.55853 8.06827 7.56123 10.0638 5.56097 12.0617C5.57754 12.0635 5.59587 12.0673 5.61421 12.0673C6.38793 12.0676 7.16165 12.0694 7.93537 12.0641C7.99453 12.0638 8.0664 12.025 8.10988 11.9815C10.0386 10.0579 11.9646 8.13156 13.8906 6.20525C14.0364 6.05943 14.0367 6.01122 13.89 5.86452C11.9634 3.93732 10.0368 2.00982 8.10692 0.0852753C8.06108 0.0397275 7.98151 0.00453144 7.91763 0.00393991C7.16372 -0.00167963 6.40952 9.49614e-05 5.65562 0.000982256C5.62042 0.000982256 5.58523 0.00837638 5.52252 0.0157705C7.53935 2.032 9.53576 4.02782 11.5686 6.06032Z"
                          fill="#4CCDFF"
                        />
                      </svg>
                    </a>
                  </span>
                </div>
              )}
              <div className="right">
                <div className="formBuyTicketStep">
                  <div className="form-detail" style={{ marginBottom: "20px" }}>
                    <div style={{ display: "flex" }}>
                      <div className={styles.flexCol} style={{ width: "50%" }}>
                        <span className="detail-text">Available Tickets</span>
                        <span className="detail-number">
                          {ticketBalance - ticketUsed}
                        </span>
                      </div>
                      <div className={styles.flexCol} style={{ width: "50%" }}>
                        <span className="detail-text">Used Tickets</span>
                        <span className="detail-number">
                          {ticketUsed}/{ticketBalance}
                        </span>
                      </div>
                    </div>
                  </div>

                  <span className="detail-text">Number of ticket</span>

                  <NumberFormat
                    className={styles.buyTokenInput}
                    placeholder={"Enter number of ticket"}
                    thousandSeparator={true}
                    onChange={handleChangeTicketAmountStep}
                    decimalScale={6}
                    defaultValue={ticketAmountStep}
                    // defaultValue={maximumBuy || 0}
                    // isAllowed={checkAllowInput}
                    // max={tokenBalance}
                    min={0}
                    maxLength={255}
                    // disabled={wrongChain}
                  />
                  <Button
                    style={{ background: "#4CCDFF", color: "#000" }}
                    label={isConfirming ? "..." : "Confirm"}
                    loading={isConfirming}
                    buttonType="secondary"
                    onClick={handleConfirm}
                  />
                  <div
                    className={styles.flexRow}
                    style={{
                      alignItems: "center",
                      marginTop: "20px",
                      fontSize: "16px",
                    }}
                  >
                    <span>If you haven't bought a ticket, click to buy</span>
                    <Button
                      label="Buy Tickets"
                      buttonType="primary"
                      className={styles.smallButton}
                      onClick={() => handleScroll("buy-tickets")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Winner />
        <div className={styles.buyTicketFooter}>
          <div className={styles.buyTicketFooterLeft}>
            <h4 style={{ fontSize: 18, fontWeight: 500 }}>
              CURRENT $GAFI REWARD{" "}
            </h4>
            <h4 style={{ fontSize: 24, lineHeight: "32px", marginTop: 20 }}>
              Pool Prize :{" "}
              161.52
              $GAFI
              <br />
              Reward from GameFi team: 45 $GAFI
            </h4>
          </div>
          <div className={styles.buyTicketFooterRight}>
            {nextRoundStart > new Date().getTime() ? (
              <h5 style={{ fontSize: 18, fontWeight: 500 }}>
                {nextRoundLabel === "ENDED"
                  ? `THE LAST DRAW WILL END IN`
                  : `THE ${nextRoundLabel} DRAW STARTS IN`}
              </h5>
            ) : (
              <h5 style={{ fontSize: 18, fontWeight: 500 }}>
                THE EVENT HAS ENDED
              </h5>
            )}
            <CountdownWhite startDate={new Date(nextRoundStart)} />
          </div>
        </div>
      </div>
    );
  };

  const renderJoinJackpot = () => {
    return (
      <div className={styles.groupJoin} ref={joinRef}>
        <div className={styles.whoJoin}>
          <div style={{ padding: "60px 80px" }}>
            <div className="special">🔥 Who can join?🔥</div>
            <img src={whoCanJoinText} width={350} style={{ marginTop: 50 }} />
            {/*<h2 className="text-join" style={{ marginTop: 42 }}>
              Everyone who interested in blockchain...
            </h2>
            <h1 className="text-join" style={{ marginTop: 40 }}>
              Our talents
            </h1>
            <h1 className="text-join" style={{ marginTop: 6 }}>
              Our customers
            </h1>
            <h1 className="text-join" style={{ marginTop: 6 }}>
              Our partners
            </h1> <h2 className="text-join" style={{ marginTop: 121 }}>
              {" "}
              ...and all of your friends
            </h2>*/}
          </div>
        </div>
        <div className={styles.howJoin}>
          <div style={{ padding: "60px 80px" }}>
            <div className="special">🔥 How to join?🔥</div>
            <img
              src={joinStep}
              style={{ marginBottom: 10, width: "100%", objectFit: "contain" }}
              alt=""
            />
            <span className="join-guide">
              {`
            First, get yourself a ticket of 0.005 $GAFI in the “Buy ticket” box below. Once you have the ticket, just need to wait till “Drawing days” which will happen on October 27, October 29 and November 1.
            
            The result is then announced on this website, and if you are one of the lucky winners, the prize will be airdropped directly to your wallet address from November 1 to November 3. 
            `}
            </span>
            <a
              href={
                "https://medium.com/gamefi-official/celebrating-icetea-labs-3rd-anniversary-with-special-prizes-worth-12-000-d53cf7537463"
              }
              target={"_blank"}
            >
              <span className="view-details">VIEW RULE DETAILS</span>
              <img src={iconGoto} style={{ width: 14, marginLeft: 6 }} alt="" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderBuyTicket = () => {
    // @ts-ignore
    return (
      <div ref={buyTicketRef} className={styles.buyTicket}>
        <div className="left">
          <span className="title">BUY TICKET</span>
          <img src={underBuyTicket} width={237} />
          <span className="text-guide">
            {`
            1. Price per ticket: 0.005 $GAFI. 
            2. No limit to the number of tickets you can buy.
            3. You can select the number of tickets to be used for each Lottery Draw.
            4. Each ticket can only be used 1 time.
            5. The precise moment of the lucky draw is randomly determined within the Lucky Drawing Day. 
            `}
          </span>
          <br />
          <a
            href={
              "https://medium.com/gamefi-official/celebrating-icetea-labs-3rd-anniversary-with-special-prizes-worth-12-000-d53cf7537463"
            }
            target={"_blank"}
            style={{ color: "#4CCDFF", fontSize: 18, marginTop: 20 }}
          >
            {"READ MORE"}{" "}
            <svg
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="#4CCDFF"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.03597 6.04524C4.02063 8.05851 2.02274 10.054 0.0068026 12.0676C0.074237 12.0676 0.121264 12.0676 0.16829 12.0676C0.897647 12.0676 1.627 12.0655 2.35636 12.07C2.45929 12.0706 2.52968 12.0377 2.60066 11.9667C4.51515 10.0493 6.43111 8.13363 8.34649 6.21767C8.50975 6.0544 8.51064 6.01566 8.35152 5.85654C6.42816 3.93288 4.50509 2.00893 2.57848 0.0882329C2.52997 0.0397275 2.4442 0.0048272 2.37558 0.00423567C1.63144 -0.00167963 0.887295 0.000390726 0.14315 0.000982256C0.102039 0.000982256 0.0612234 0.00689756 0 0.011334C2.01357 2.02402 4.00969 4.01954 6.03597 6.04524ZM11.5686 6.06032C9.55853 8.06827 7.56123 10.0638 5.56097 12.0617C5.57754 12.0635 5.59587 12.0673 5.61421 12.0673C6.38793 12.0676 7.16165 12.0694 7.93537 12.0641C7.99453 12.0638 8.0664 12.025 8.10988 11.9815C10.0386 10.0579 11.9646 8.13156 13.8906 6.20525C14.0364 6.05943 14.0367 6.01122 13.89 5.86452C11.9634 3.93732 10.0368 2.00982 8.10692 0.0852753C8.06108 0.0397275 7.98151 0.00453144 7.91763 0.00393991C7.16372 -0.00167963 6.40952 9.49614e-05 5.65562 0.000982256C5.62042 0.000982256 5.58523 0.00837638 5.52252 0.0157705C7.53935 2.032 9.53576 4.02782 11.5686 6.06032Z"
                fill="#4CCDFF"
              />
            </svg>
          </a>
        </div>
        <div className="right">
          <div className={styles.formBuyTicket}>
            {active ? (
              <div className={styles.addressContainer}>
                <span>{`${account?.substr(0, 14)}...${account?.substr(
                  -14
                )}`}</span>
                <a style={{ cursor: "pointer" }} onClick={handleDisconnect}>
                  Disconnect
                </a>
              </div>
            ) : (
              <Button
                label="Connect Wallet"
                buttonType="secondary"
                onClick={handleConnect}
              />
            )}
            <div className={`form-detail ${styles.flexCol}`}>
              <span className="detail-text">Your $GAFI Balance</span>
              <span className="detail-number">{gameFiBalance.toFixed(3)}</span>
            </div>
            <div className="form-detail">
              <div style={{ display: "flex" }}>
                <div className={styles.flexCol} style={{ width: "50%" }}>
                  <span className="detail-text">Available Tickets</span>
                  <span className="detail-number">
                    {ticketBalance - ticketUsed}
                  </span>
                </div>
                <div className={styles.flexCol} style={{ width: "50%" }}>
                  <span className="detail-text">Used Tickets</span>
                  <span className="detail-number">
                    {ticketUsed}/{ticketBalance}
                  </span>
                </div>
              </div>
            </div>
            <br />
            <span className="detail-text">Number of ticket</span>

            <NumberFormat
              className={styles.buyTokenInput}
              placeholder={"Enter number of ticket"}
              thousandSeparator={true}
              onChange={handleChangeTicketAmount}
              decimalScale={6}
              defaultValue={ticketAmount}
              // defaultValue={maximumBuy || 0}
              // isAllowed={checkAllowInput}
              // max={tokenBalance}
              min={0}
              maxLength={255}
              // disabled={wrongChain}
            />
            {isApproved ? (
              <Button
                style={{ background: "#F1BA40", color: "#000" }}
                loading={isLoading}
                label={isLoading ? "..." : "Purchase"}
                buttonType="primary"
                onClick={handlePurchase}
              />
            ) : (
              <Button
                style={{ background: "#F1BA40", color: "#000" }}
                label={isLoading ? "..." : "Approve token"}
                loading={isLoading}
                buttonType="secondary"
                onClick={handleApprove}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderEvent = () => {
    return (
      <div className={styles.cardEvent}>
        <div className={styles.cardEventTitle}>
          RED KITE
          <br />
          MOMENT
        </div>
        <p style={{ fontSize: 20, fontWeight: 600, lineHeight: "50px" }}>
          Event to listen to our members’ thoughts and experience
        </p>
        <br />
        <a href={"/#/airdrop"}>
          Discover
        </a>
        <div className={styles.cardEventDetail}>
          <div className={styles.cardEventDetailLeft}>
            <div className={styles.cardEventDetailItem}>
              <img src={youtute} width={200} />
              <h1>8,000 PKF</h1>
            </div>
            <div
              className={styles.cardEventDetailItem}
              style={{
                borderLeft: "1px solid #ffffff30",
                borderRight: "1px solid #ffffff30",
                paddingLeft: 20,
              }}
            >
              <img src={medium} width={200} />
              <h1>3,500 PKF</h1>
            </div>
            <div
              className={styles.cardEventDetailItem}
              style={{ marginLeft: 20 }}
            >
              <img src={twitter} width={200} />
              <h1>600 PKF</h1>
            </div>
          </div>
          <div className={styles.cardEventDetailRight}>
            🔥 and Community <br /> Prizes 🔥
          </div>
        </div>
      </div>
    );
  };

  const handleScroll = (link: string) => {
    console.log(link);
    switch (link) {
      case "rewards":
        return rewardsRef?.current?.scrollIntoView({ behavior: "smooth" });
      case "how-to-join":
        return joinRef?.current?.scrollIntoView({ behavior: "smooth" });
      case "buy-tickets":
        return buyTicketRef?.current?.scrollIntoView({ behavior: "smooth" });
      default:
        break;
    }
  };

  return (
    <div className={commonStyle.DefaultLayout}>
      <div className={commonStyle.bgBody}>
        <div className={styles.navBar}>
          <div>
            <a href="#">
              <img src={logo} alt="" />
            </a>
          </div>
          <div className={styles.rightHead}>
            <ButtonLink
              text="Rewards"
              to={"#rewards"}
              onClick={() => handleScroll("rewards")}
              className={`${styles.btn} startMobile`}
            />
            <ButtonLink
              text="How to join"
              to={"#how-to-join"}
              className={`${styles.btn} startMobile`}
              onClick={() => handleScroll("how-to-join")}
            />
            <Button
              label="Buy Tickets"
              buttonType="primary"
              className={styles.smallButton}
              // style={{ position: "absolute", right: 100, top: 20 }}
              onClick={() => handleScroll("buy-tickets")}
            />
          </div>
        </div>
        <MainDefaultLayout>
          <div className={styles.eventLayout}>
            <div className={styles.container}>
              {renderBanner()}
              {renderBuyTicketStep()}
              {renderJoinJackpot()}
              {renderBuyTicket()}
              {renderEvent()}
              <NotificationContainer />
            </div>
          </div>
        </MainDefaultLayout>
        <FooterLandingLayout />
      </div>
    </div>
  );
};

export default withRouter(EventJackpot);
