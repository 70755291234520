import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    countdownBottom: {
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",

      "& .display": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "24px",
        marginBottom: 34,
        marginTop: 120,
        color: "#fff",
      },
    },
  };
});

export default useStyles;
