import React from "react";
//@ts-ignore
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import {
  HashRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
// import { AppContext } from "./AppContext";
import ErrorBoundary from "./components/Base/ErrorBoundary";
import EventIcetea from "./pages/Event";
import EventBoxes from "./pages/EventBoxes";
import EventJackpot from "./pages/EventJackpot";
import EventAirdrop from "./pages/EventAirdrop";
import NotFoundPage from "./pages/NotFoundPage";
import BurnBanner from "./pages/GafiBurnEventBanner";

/**
 * Main App routes.
 */
const Routes: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  // const dispatch = useDispatch();
  // const alert = useSelector((state: any) => state.alert);
  // const { history } = props;

  // const { deactivate } = useWeb3React();
  // const [binanceAvailable, setBinanceAvailable] = useState(false);
  // const [openConnectWallet, setOpenConnectWallet] = useState<boolean>(false);
  // const [currentConnectedWallet, setCurrentConnectedWallet] = useState<any>(undefined);

  // const logout = () => {
  //     deactivate();
  //     dispatch(settingAppNetwork(NetworkUpdateType.Wallet, undefined));
  //     setCurrentConnectedWallet(undefined);
  //     handleConnectorDisconnect();
  // }

  // const {
  //     handleProviderChosen,
  //     connectWalletLoading,
  //     currentConnector,
  //     walletName,
  //     setWalletName,
  //     loginError,
  //     appNetworkLoading,
  //     handleConnectorDisconnect,
  // } = useProviderConnect(
  //     setOpenConnectWallet,
  //     openConnectWallet,
  //     binanceAvailable
  // );

  // useEffect(() => {
  //     document.onreadystatechange = function () {
  //         if (document.readyState == "complete") {
  //             setBinanceAvailable(true);
  //         }
  //     }
  // }, []);

  // useEffect(() => {
  //     const { type, message } = alert;
  //     if (type && message) {
  //         NotificationManager[type](message);
  //     }
  // }, [alert]);

  // useEffect(() => {
  //     history.listen((location, action) => {
  //         dispatch(clearAlert());
  //     });
  // }, []);

  return (
    // <AppContext.Provider
    //     value={{
    //         binanceAvailable,
    //         handleProviderChosen,
    //         connectWalletLoading,
    //         walletName,
    //         setWalletName,
    //         loginError,
    //         appNetworkLoading,
    //         handleConnectorDisconnect,
    //         currentConnector,
    //         logout,
    //         setCurrentConnectedWallet,
    //         openConnectWallet,
    //         setOpenConnectWallet,
    //         currentConnectedWallet
    //     }}
    // >
    <>
      <Switch>
        {/* <Route
            exact path="/"
            render={() => <Redirect to={`${'/home'}`} />}
          /> */}
        <Route path={"/airdrop"} component={EventAirdrop} />
        <Route path={"/jackpot"} component={EventJackpot} />
        {/* <Route path={"/box"} component={EventBoxes} /> */}
        <Route path={"/"} component={EventIcetea} />

        <Route component={NotFoundPage} />
      </Switch>
    </>
    // </AppContext.Provider>
  );
};

const RoutesHistory = withRouter(Routes);

const routing = function createRouting() {
  return (
    <>
        <BurnBanner />
      <NotificationContainer />
      <Router>
        {/* <AppContainer> */}
        <ErrorBoundary>
          <RoutesHistory />
        </ErrorBoundary>
        {/* </AppContainer> */}
      </Router>
    </>
  );
};
/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default routing;
