import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import TweetEmbed from "react-tweet-embed";
import ButtonLink from "../../components/Base/ButtonLink";
import Countdown from "../../components/Base/Countdown";
import FooterLandingLayout from "../../components/Base/FooterLandingLayout";
import useHeaderStyles from "../../components/Base/HeaderDefaultLayout/styles";
import { useCommonStyle } from "../../styles";
import useStyles from "./style";

const watchIcon = "/images/icons/button-play.svg";
const cardJackpot = "/images/card-jackpot.png";
const cardAirdrop = "/images/card-airdrop.png";
const feedsBanner = "/images/icons/group-anniversary.png";
const telegramIcon = "/images/icons/socials-telegram.svg";
const twitterIcon = "/images/icons/socials-twitter.svg";
const facebookIcon = "/images/icons/socials-facebook.svg";
const mediumIcon = "/images/icons/socials-medium.svg";
const youtubeIcon = "/images/icons/socials-youtube.svg";
const third = "/images/third.svg";
const logo = "/images/logo-text.svg";

const SOCIALS_LINK = {
  TELEGRAM: "https://t.me/PolkaFoundry",
  TWITTER: "https://twitter.com/Icetea_Labs",
  FACEBOOK: "https://www.facebook.com/icetea.io/",
  MEDIUM: "https://medium.com/polkafoundry",
  YOUTUBE: "https://www.youtube.com/channel/UCjoCuoFSA-ic8TF1XagzdgQ",
};
const YOUTUBE_URI = "https://www.youtube.com/embed/za2IheggsT4";
// #3YearIcetea #HappyBDay #RedKite $PKF => URL encode
const TWEET_TAGS = `%233YearIcetea%20%23HappyBDay%20%23RedKite%20%24PKF`;

const EventIcetea = (props: any) => {
  const styles = useStyles();
  const headerStyles = useHeaderStyles();
  const commonStyle = useCommonStyle();
  const celebratingRef = useRef<null | HTMLDivElement>(null);
  const youtubeRef = useRef<null | HTMLDivElement>(null);
  const gamesRef = useRef<null | HTMLDivElement>(null);
  const feedsRef = useRef<null | HTMLDivElement>(null);
  const [playYoutube, setPlayYoutube] = useState<boolean>(false);
  const [dataTweets, setDataTweets] = useState<Array<number> | undefined>(
    undefined
  );

  useEffect(() => {
    const getData = async () => {
      const response: any = await axios(
        `https://redkite-social-api.polkafoundry.com/api/v1/social-network/twitter/search?tag=${TWEET_TAGS}`
      );
      let tweets = response?.data?.data;

      console.log(tweets);
      if (tweets) setDataTweets(tweets);
    };
    getData();
  }, []);
  const handleWatchVideo = () => {
    handleScroll("Video");
    setPlayYoutube(true);
  };

  const renderCelebrating = () => {
    return (
      <div className={styles.banner} ref={celebratingRef}>
        <div className="top-banner">
          <div className="top">
            <div className="title">Celebrating 3rd anniversary with</div>
            <div className="video" onClick={handleWatchVideo}>
              <img src={watchIcon} alt="" />
              <span>Watch video</span>
            </div>
          </div>
          <span className="text">ICETEA</span>
        </div>
        <div className="timing">
          <img src={third} alt="" />
          <div className="countdown">
            <Countdown />
          </div>
          <span className="date">NOVEMBER 1, 2021</span>
        </div>
      </div>
    );
  };

  const renderYoutube = () => {
    return (
      <div className="youtube" ref={youtubeRef}>
        <div className="from">From Icetea with Love</div>
        <div className="media">
          <div className="video-responsive">
            <iframe
              width="800"
              height="450"
              src={playYoutube ? `${YOUTUBE_URI}?autoplay=1` : YOUTUBE_URI}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderEvent = () => {
    return (
      <div className="event" ref={gamesRef}>
        <div className="title">Join us for the Biggest Events and Rewards</div>
        <div className="description">
          We are proud to have you on board as our talent, our customer and our
          partner. On this special event, to honor your support and trust, we
          present you many airdrop and lottery events.
          <br />
          Let’s the celebration begins!
        </div>

        <div className={styles.listCard}>
          <a href="#/jackpot">
            <img src={cardJackpot} alt="" />
          </a>
          <a href="#/airdrop">
            <img src={cardAirdrop} alt="" />
          </a>
        </div>
      </div>
    );
  };

  const renderSocialFeeds = () => {
    return (
      <div className={styles.socialFeeds} ref={feedsRef}>
        <div className="top">
          <div className="about">
            <span>Social Feeds</span>
            <div className="description">
              We would like to send our sincere thanks to our beloved community
              for accompanying and supporting us until now. Let your voice be
              heard!
            </div>
            <div className="social-icons">
              <a href={SOCIALS_LINK.TELEGRAM} target="_blank">
                <img src={telegramIcon} alt="" />
              </a>
              <a href={SOCIALS_LINK.TWITTER} target="_blank">
                <img src={twitterIcon} alt="" />
              </a>
              <a href={SOCIALS_LINK.FACEBOOK} target="_blank">
                <img src={facebookIcon} alt="" />
              </a>
              <a href={SOCIALS_LINK.MEDIUM} target="_blank">
                <img src={mediumIcon} alt="" />
              </a>
              <a href={SOCIALS_LINK.YOUTUBE} target="_blank">
                <img src={youtubeIcon} alt="" />
              </a>
            </div>
          </div>
          <img className="img-banner" src={feedsBanner} alt="" />
        </div>
        <div className={styles.listTweets}>
          {dataTweets?.map((id: any) => {
            return (
              <TweetEmbed className="tweetItems" id={id} key={Number(id)} />
            );
          })}
        </div>
      </div>
    );
  };

  const handleScroll = (link: string) => {
    console.log(link);
    switch (link) {
      case "Celebrating":
        return celebratingRef?.current?.scrollIntoView({ behavior: "smooth" });
      case "Video":
        return youtubeRef?.current?.scrollIntoView({ behavior: "smooth" });
      case "Minigames":
        return gamesRef?.current?.scrollIntoView({ behavior: "smooth" });
      case "Social feeds":
        return feedsRef?.current?.scrollIntoView({ behavior: "smooth" });

      default:
        break;
    }
  };

  return (
    <div className={commonStyle.DefaultLayout}>
      <div className={commonStyle.bgBody}>
        <div className={headerStyles.navBar}>
          <div>
            <a href="#">
              <img src={logo} alt="" />
            </a>
          </div>
          <div className={headerStyles.rightHead}>
            <ButtonLink
              text="Celebrating"
              to={"#celebrating"}
              onClick={() => handleScroll("Celebrating")}
              className={`${headerStyles.btn} startMobile`}
            />
            <ButtonLink
              text="Video"
              onClick={() => handleScroll("Video")}
              to={"#video"}
              className={`${headerStyles.btn} startMobile`}
            />
            <ButtonLink
              text="Minigames"
              to={"#minigames"}
              onClick={() => handleScroll("Minigames")}
              className={`${headerStyles.btn} startMobile`}
            />
            <ButtonLink
              text="Social feeds"
              to={"#social-feeds"}
              onClick={() => handleScroll("Social feeds")}
              className={`${headerStyles.btn} startMobile`}
            />
          </div>
        </div>

        <div className={styles.mainLayout}>
          <div className={styles.eventLayout}>
            <div className={styles.container}>
              {renderCelebrating()}
              {renderYoutube()}
              {renderEvent()}
              {renderSocialFeeds()}
            </div>
          </div>
        </div>

        <FooterLandingLayout />
      </div>
    </div>
  );
};

export default withRouter(EventIcetea);
