import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    countDown: {
      position: 'absolute',
      left: '50%',
      top: '55%',
      transform: 'translate(-50%, -50%)',
      //margin: "0 auto",

      "& .countdownPart": {
        display: "inline-block",
        listStyleType: "none",
        padding: "0px 20px",
        font: "normal normal bold 40px/48px DM Sans",
        color: "#fff",

        "& span": {
          display: "block",
          textAlign: "center",
          color: "#fff",
        },

        "&.number": {
          display: "block",
          minWidth: 40,
          fontWeight: "bold",
          fontSize: 36,
          lineHeight: "56px",
        },
      },

      "& .listCountDown": {
        display: "flex",
        alignItems: "flex-start",

        [theme?.breakpoints?.down("sm")]: {
          textAlign: "center",
          justifyContent: "center",
        },
      },

      "& .countdownInfo": {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "18px",
        color: "#fff !important",
      },

      [theme.breakpoints.down("xs")]: {
        "& .countdownPart": {
          padding: "10px 5px",

          "&.number": {
            padding: "5px 5px 15px 5px",
          },
        },
      },
    },

    countdownBottom: {
      margin: "0 auto",
      paddingBottom: 100,
      "& .countdownPart": {
        display: "inline-block",
        listStyleType: "none",
        padding: "0px 20px",
        font: "normal normal bold 55px/48px DM Sans",
        color: "#00C7F4",

        "& span": {
          display: "block",
          textAlign: "center",
          color: "#00C7F4",
        },

        "&.number": {
          display: "block",
          minWidth: 60,
          fontWeight: "bold",
          fontSize: 64,
          lineHeight: "56px",
        },
      },

      "& .listCountDown": {
        display: "flex",
        alignItems: "flex-start",

        [theme?.breakpoints?.down("sm")]: {
          textAlign: "center",
          justifyContent: "center",
        },
      },

      "& .countdownInfo": {
        fontWeight: "normal",
        fontFamily: "DM Sans",
        fontSize: 16,
        lineHeight: "18px",
        color: "#fff",
      },

      [theme.breakpoints.down("xs")]: {
        "& .countdownPart": {
          padding: "10px 5px",

          "&.number": {
            padding: "5px 5px 15px 5px",
          },
        },
      },
    },
  };
});

export default useStyles;
