import React, { useEffect, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import ButtonLink from "../../components/Base/ButtonLink";
import CountdownBottom from "../../components/Base/CountdownBottom";
import useHeaderStyles from "../../components/Base/HeaderDefaultLayout/styles";
import { useCommonStyle } from "../../styles";
import useStyles from "./style";

const iconGoto = "/images/icons/icon-goto.svg";
const joinStep = "/images/airdrop-join-step.svg";
const prize = "/images/bg-prize.png";
const bgMedium = "/images/bg-medium.svg";
const clock = "/images/icon-timer.svg";
const logo = "/images/logo-text.svg";
const youtute = "/images/icons/prize-youtube.png";
const medium = "/images/icons/prize-medium.png";
const twitter = "/images/icons/prize-twitter.png";
const community = "/images/icons/prize-community.png";

const RULES_URL = "https://link.medium.com/hHqZppvDFkb";
const FORM_URL = "https://forms.gle/9117WLA5g9Sehgz46";

const EventAirdrop = (props: any) => {
  const styles = useStyles();
  const headerStyles = useHeaderStyles();
  const commonStyle = useCommonStyle();
  const rewardsRef = useRef<null | HTMLDivElement>(null);
  const joinRef = useRef<null | HTMLDivElement>(null);
  let location = useHistory();

  useEffect(() => {
    console.log("location", location);
    // window.onpopstate()
    window.scroll({ top: 0 });
    // setTimeout(function () {
    // }, 500);
  }, []);

  const renderBanner = () => {
    return (
      <div className={styles.banner}>
        <div className="text-banner">
          <span>Red kite</span>
          <span className="text-airdrop">Moment</span>
        </div>
        <div
          className={styles.flexColumn}
          style={{ marginTop: 30, marginLeft: "auto" }}
        >
          <span className="text-detail" style={{ marginBottom: "16px" }}>
            Upon Icetea Labs’s 3-year anniversary, we would like to send our
            sincere thanks to our beloved community for accompanying and
            supporting us until now.
          </span>
          <span className="text-detail">
            In response to community feedback on how to further enhance Red
            Kite’s service quality, we hold an event to listen to our members’
            thoughts and experience with Red Kite.
          </span>
        </div>
      </div>
    );
  };

  const renderRewards = () => {
    return (
      <div className={`${styles.airdropPrize}`} ref={rewardsRef}>
        <div className={styles.gleam}>
          <div className="gleam-left">
            <h1>
              Participants with the highest-quality submissions will have a
              chance to get redundant prizes.
            </h1>
          </div>
          <div className={styles.flexColumn}>
            <div className="gleam-youtube">
              <div className="special">🔥SPECIAL PRIZE 🔥</div>
              <div className="for-socials bg-youtbe">
                <img src={youtute} alt="" />
                <span className="text-prize">For YouTube videos</span>
              </div>
              <span className="prize-number">8,000 PKF</span>
              <img className="img-youtube" src={prize} alt="" />
            </div>
            <div className="gleam-medium">
              <div className="for-socials">
                <img src={medium} alt="" />
                <span className="text-prize">For Medium articles</span>
              </div>
              <span className="prize-number">3,500 PKF</span>
              <img className="img-medium" src={bgMedium} alt="" />
            </div>
            <div className={styles.socialsPrize}>
              <div className="prize-left">
                <div className="for-socials">
                  <img src={twitter} alt="" />
                  <span className="text-prize">For Twitter posts</span>
                </div>
                <span className="prize-number">600 PKF</span>
              </div>
              <div className="prize-right">
                <div className="for-socials">
                  <img src={community} alt="" />
                  <span className="text-prize">Community prizes</span>
                </div>
                <span className="prize-number">500 PKF</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderJoin = () => {
    return (
      <div className={styles.groupJoin} ref={joinRef}>
        <div className={styles.whoJoin}>
          <div className='join-inside'>
            <div className="special">🔥Who can join?🔥</div>
            <h2 className="text-join" style={{ marginTop: 42 }}>
              All Red Kite members
            </h2>
            <span style={{ marginTop: 10 }}>
              By sharing your memorable experiences with Red Kite or other
              products of Icetea Labs, all community members will get a chance
              to win “Red Kite Moment” event.
            </span>
            <div className="join-time">
              <img src={clock} style={{ marginRight: 8 }} alt="" />
              <span>02:00 PM UTC, Oct 26 - 02:00 PM UTC, Oct 31</span>
            </div>
          </div>
        </div>
        <div className={styles.howJoin}>
          <div style={{ padding: "60px 80px", maxWidth: '720px' }}>
            <div className="special">🔥How to join?🔥</div>
            <img
              src={joinStep}
              style={{ marginBottom: 10, width: "100%", objectFit: "contain" }}
              alt=""
            />
            <div className="join-guide">
              <span style={{ marginTop: 18 }}>
                <b>Step 1:</b> Write down your special moments when experiencing
                Red Kite or other products of Icetea Labs. It can be displayed
                in form of a Twitter post/ a Medium article/ a Youtube video
                with hashtag <br /> <b>#3YearIcetea #HappyBDay #RedKite $PKF</b>
                .
              </span>
              <span style={{ marginTop: 12 }}>
                <b>Step 2:</b> Submit your results into this form
                {` >> Click `}{" "}
                <a
                  href={FORM_URL}
                  target="_blank"
                  style={{
                    display: "contents",
                    margin: 0,
                    color: "#00004F",
                    textDecoration: "underline",
                  }}
                >
                  HERE
                </a>
                .
              </span>
            </div>
            <a href={RULES_URL} target="_blank">
              <span className="view-details">VIEW RULE DETAILS</span>
              <img src={iconGoto} style={{ width: 14, marginLeft: 6 }} alt="" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderEvent = () => {
    return (
      <div className={styles.cardEvent}>
        <div className={styles.cardEventTitle}>
          <span className="left">Gamefi</span>
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: 30 }}>Lucky </span>
            <span>tickets</span>
          </div>
          <p>Chance to win the prize up to $10,000</p>
          <a className="discover" href={"#/jackpot"}>
            Discover
          </a>
        </div>
        <div className={styles.cardEventDetail}>
          <div className="about">
            <div className="special">🔥 SPECIAL PRIZE 🔥</div>
            <span className="content">
              All the $GAFI from bought tickets throughout the game
            </span>
          </div>
          <div className={styles.gafiPrize}>
            <div className="prize-top">
              <span className="text-prize">3 draws with a total prize of </span>
              <span className="prize-number">$9,000</span>
            </div>
            <div className="prize-bot">
              <span className="text-prize">
                Join event by getting a ticket of
              </span>
              <div style={{ marginTop: 10 }}>
                <span className="prize-number">0.005</span>
                <span className="prize-currency">$GAFI</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleScroll = (link: string) => {
    console.log(link);
    switch (link) {
      case "rewards":
        return rewardsRef?.current?.scrollIntoView({ behavior: "smooth" });
      case "how-to-join":
        return joinRef?.current?.scrollIntoView({ behavior: "smooth" });
      default:
        break;
    }
  };

  return (
    <div className={commonStyle.DefaultLayout}>
      <div className={commonStyle.bgBody}>
        <div className={headerStyles.navBar}>
          <div>
            <a href="#">
              <img src={logo} alt="" />
            </a>
          </div>
          <div className={headerStyles.rightHead}>
            <ButtonLink
              text="REWARDS"
              to={"#rewards"}
              onClick={() => handleScroll("rewards")}
              className={`${headerStyles.btn} startMobile`}
            />
            <ButtonLink
              text="HOW TO JOIN"
              to={"#how-to-join"}
              onClick={() => handleScroll("how-to-join")}
              className={`${headerStyles.btn} startMobile`}
            />
            <a href={FORM_URL} target="_blank" className={styles.joinEvent}>
              Join Event
            </a>
          </div>
        </div>

        <div className={styles.mainLayout}>
          <div className={styles.eventLayout}>
            <div className={styles.container}>
              {renderBanner()}
              {renderRewards()}
              {renderJoin()}
              {renderEvent()}
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <CountdownBottom />
          <p className={styles.copyRight}>© Icetea Labs, 2021</p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EventAirdrop);
