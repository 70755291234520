import { makeStyles } from "@material-ui/core";
const bg = "/images/bg-event-banner.svg";
const bgWhoJoin = "/images/who-can-join.png";
const bgPrizeLeft = "/images/bg-4draws.svg";
const gafi = "/images/icons/ticket-gafi.png";
const buyTicketLeft = "/images/buy-ticket-left-bg.png";
const buyTicketRight = "/images/buy-ticket-right-bg.png";
const redkiteBG = "/images/redkite-banner-bg.png";

const useStyles = makeStyles((theme: any) => {
  return {
    navBar: {
      gridArea: "header",
      height: 80,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "0 auto",
      width: "1440px",
      maxWidth: "calc(100vw - 240px)",
      padding: "0 120px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.only("md")]: {
        maxWidth: "calc(100vw - 160px)",
      },
      [theme.breakpoints.down("sm")]: {
        // flexDirection: "column",
        margin: "10px auto",
        maxWidth: "calc(100vw - 60px)",
        padding: "0",
      },
    },
    rightHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    btn: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      textTransform: "uppercase",
      fontSize: "14px",
      lineHeight: "16px",
      // letterSpacing: "1px",
      color: "#FFFFFF",
      mixBlendMode: "normal",
      backgroundColor: "none",
      border: "none",
      cursor: "pointer",

      "&:focus": {
        outline: "none",
      },
      "&.my-account": {
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
        marginLeft: 10,
        font: "normal normal bold 14px/18px DM Sans",

        "& img": {
          marginRight: 4,
        },

        "& .icon": {
          width: 20,
          filter: "brightness(0) invert(1)",
        },

        "& span": {
          display: "flex",
          alignItems: "center",
        },

        "&:hover": {
          color: "white",
        },

        [theme.breakpoints.only("xs")]: {
          marginTop: 0,
          marginBottom: 20,
        },
      },
    },

    eventLayout: {
      backgroundColor: "#00004F",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      color: "#fff",
      paddingTop: 68,
      width: "100%",
      /*background: `url(${bg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "90%",
      backgroundPosition: "top",
      backgroundPositionY: "75px",*/

      "& .feeds": {
        paddingLeft: "160px",
        paddingBottom: 80,
        color: "#0505DD",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F1BA40",

        "& .top": {
          display: "flex",
          marginBottom: 95,
          "& .social-icons": {
            marginTop: "auto",
            marginBottom: 8,
            "& a": {
              marginRight: 16,
            },
          },
        },

        "& .img-banner": {
          marginLeft: "auto",
          marginBottom: "auto",
        },

        "& .about": {
          display: "flex",
          flexDirection: "column",

          "& span": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "42px",
            lineHeight: "42px",
            marginTop: 84,
            marginBottom: 23,
          },

          "& .description": {
            maxWidth: "450px",
            fontFamily: "Roboto-Regular",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "18px",
            lineHeight: "28px",
          },
        },
      },

      "& .title": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "40px",
        lineHeight: "60px",
      },

      "& .text-guide": {
        whiteSpace: "pre-line",
        fontFamily: "Roboto-Regular",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        lineHeight: "28px",
        maxWidth: 520,
      },

      "& .detail-text": {
        fontFamily: "Roboto-Regular",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
      },

      "& .detail-number": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "24px",
        lineHeight: "28px",
      },
    },

    wrongChainAlert: {
      background: "#ff4343",
      textAlign: "center",
      padding: 5,
    },

    banner: {
      width: "100%",
      display: "flex",
      flexDirection: "column",

      "& .text-banner": {
        display: "flex",
        flexDirection: "column",
        margin: "70px 0 0 137px",
        "& span": {
          font: "normal normal normal 120px/120px Android-101",
        },

        [theme.breakpoints.down("md")]: {
          margin: "70px 120px",
        },

        [theme.breakpoints.down("sm")]: {
          margin: "70px 20px",
          "& span": {
            font: "normal normal normal 70px/70px Android-101 ",
          },
          "& div": {
            flexFlow: 'wrap'
          }
        },
      },

      "& .about": {
        background: `url(${gafi})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPosition: "right",
        backgroundPositionY: "25px",
        padding: "20px 28px",
        backgroundColor: "#00007B",
        borderRadius: 4,
        display: "flex",
        flexDirection: "column",
        // height: 180,

        "& .content": {
          font: "normal normal 600 24px/32px Poppins",
          maxWidth: "60%",
          marginTop: 12,
        },
      },
    },

    coinLeftBG: {
      position: "absolute",
      left: "10%",
      width: 150,
    },
    coinRightBG: {
      position: "absolute",
      right: "5%",
      width: 140,
    },

    gafiPrize: {
      display: "flex",
      marginTop: 12,
      "& .prize-left": {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        background: `url(${bgPrizeLeft})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPositionX: "24px",
        backgroundPositionY: "21px",
        padding: "19px 28px",
        backgroundColor: "#00007B",
      },

      "& .prize-right": {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "18px 42px",
        marginLeft: 12,
        backgroundColor: "#00007B",
      },

      "& .text-prize": {
        font: "normal normal 500 18px/24px Poppins",
      },
      "& .prize-number": {
        color: "#51F541",
        font: "normal normal 600 36px/44px Poppins",
        marginRight: 6,
      },
      "& .prize-currency": {
        color: "#51F541",
        font: "normal normal 600 24px/36px Poppins",
      },
    },

    groupContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      margin: "120px max(120px, 50% - 660px)",
      gap: "25px",

      "& .text-left": {
        maxWidth: 600,
        font: "normal normal 500 30px/48px Poppins",
        [theme.breakpoints.down("sm")]: {
          width: '80%',
          font: "normal normal 400 24px/28px Poppins",
        }
      },

      "& .special": {
        width: 150,
        borderRadius: 4,
        background: "#51F541",
        color: "#000",
        font: "normal normal bold 12px/18px Poppins",
        letterSpacing: 1,
        alignItems: "center",
        padding: "5px 7px 3px 7px",
      },

      [theme.breakpoints.down("md")]: {
        display: "grid",
        gridTemplateColumns: "1fr",
        margin: "0 120px 120px",
        gap: "25px",
      },

      [theme.breakpoints.down("sm")]: {
        display: "grid",
        gridTemplateColumns: "1fr",
        margin: "0 20px 120px",
        gap: "25px",
      },
    },
    groupJoin: {
      display: "flex",
      //gridTemplateColumns: "1fr 1fr",
      marginLeft: -240,
      marginRight: -240,
      [theme.breakpoints.only("md")]: {
        marginLeft: -160,
        marginRight: -160,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0,
      },
      // margin: "120px max(120px, 50% - 660px)",
      // gap: "25px",

      [theme.breakpoints.down("md")]: {
        display: "grid",
        gridTemplateColumns: "1fr",
        margin: "0",
        gap: "25px",
      },
    },

    buyTicket: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      // gap: "25px",
      width: "100%",
      padding: "115px 0 110px",

      "& .left, .right": {
        // width: "50%",
        display: "flex",
        flexDirection: "column",
        // margin: "0 auto",
      },

      "& .left": {
        maxWidth: "720px",
        // paddingLeft: 140,
      },

      "& .right": {
        // paddingRight: "172px",
        maxWidth: "720px",
        minWidth: 550,
      },

      [theme.breakpoints.down("md")]: {
        display: "grid",
        gridTemplateColumns: "1fr",
        margin: "0",
        gap: "25px",
        "& .left, .right": {
          margin: "0 auto",
        },
      },
    },

    buyTicketStepContainer: {
      marginLeft: -240,
      marginRight: -240,
      [theme.breakpoints.only("md")]: {
        marginLeft: -160,
        marginRight: -160,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0,
      },
      paddingTop: 100,
      background: "#010134",
    },

    buyTicketTimeline: {
      width: "100%",
      padding: "0 240px",
      [theme.breakpoints.down("md")]: {
        padding: "0 160px",
      },
      [theme.breakpoints.down('sm')]: {
        padding: 10,
      },
      marginBottom: 50,
    },
    buyTicketTimelineTitle: {
      fontSize: 28,
      lineHeight: "36px",
    },

    timelineDrawContainer: {
      marginTop: 20,
      width: "100%",
      display: "flex",
    },
    timelineTick: {
      marginRight: "2%",
      display: "inline-flex",
      height: 24,
      width: 8,
      borderRadius: 2,
      background: "#ffffff",
    },
    timelineSmallTick: {
      marginRight: "2%",
      display: "inline-flex",
      height: 12,
      width: 4,
      borderRadius: 2,
      background: "#ffffff",
    },

    timelineSmallTickActive: {
      marginRight: "2%",
      display: "inline-flex",
      height: 12,
      width: 4,
      borderRadius: 2,
      background: "#47C0F3",
    },

    timelineTickActive: {
      marginRight: "2%",
      display: "inline-flex",
      height: 24,
      width: 8,
      borderRadius: 2,
      background: "#47C0F3",
    },

    timelineLabelActive: {
      color: "#47C0F3",
    },

    timelineText: {
      width: "33.3%",
      display: "inline",
      "& h3": {
        marginTop: 10,
        marginBottom: 5,
        fontSize: 32,
        color: "#fff",
        lineHeight: "36px",
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px !important',
          lineHeight: "24px",
        }
      },
      color: "#ffffffa0",
      fontSize: 18,
      fontWeight: 500,
      lineHeight: "24px",
    },

    winnerContainer: {
      textAlign: 'center',
      margin: "0px 240px 120px",
      padding: "0px 60px",
      "& h2" : {
        fontSize: 28,
        lineHeight: '36px'
      },
      [theme.breakpoints.down('md')]:{
        margin: "0px 160px 120px",
      },
      [theme.breakpoints.down('sm')]:{
        margin: "0px 0 120px",
      }
    },

    drawList: {
      minWidth: 500,
      display: 'flex'
    },
    drawItem: {
      background: '#01011F',
      color: '#fff'
    },
    drawItemActive: {
      background: '#4CCDFF',
      color: '#01011F'
    },


    buyTicketStep: {
      // width: '100%',
      margin: "30px 240px 60px",
      padding: "40px 60px",
      display: "flex",
      flexDirection: "column",
      background: "#010142",
      border: "1px solid #4CCDFF",
      [theme.breakpoints.down('sm')]: {
        margin: "30px 20px 60px",
        flexWrap: 'wrap'
      },
      "& .left, .right": {
        width: "50%",
        [theme.breakpoints.down('sm')]: {
          width: "100%",
        }
      },

      "& .formBuyTicketStep": {
        width: "100%",
        paddingLeft: "60px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
          paddingLeft: "0",
        }
      },

      "& .title-header": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "28px",
        lineHeight: "36px",
        margin: "0 auto 40px",
      },

      "& .total-reward": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 32,
        lineHeight: "35px",
        marginBottom: 20,
        color: "#4CCDFF",
      },

      "& .text-step-left": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 18,
        lineHeight: "24px",
        color: "#fff",
        whiteSpace: "pre-line",
        maxWidth: 500,
        marginBottom: 10,
      },
    },

    formBuyTicket: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      background: "#00007B",
      padding: "45px 35px 35px",

      "& .form-detail": {
        width: "100%",
        height: 54,
        marginTop: 20,
      },
    },

    addressContainer: {
      background: "#020618",
      borderRadius: 2,
      padding: "20px 20px",
      "& span": {
        fontSize: 15,
      },
      "& a": {
        float: "right",
        color: "#F1BA40",
        textDecoration: "underline",
      },
    },

    cardEvent: {
      background: `url(${redkiteBG})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      //display: ,
      //gridTemplateColumns: "1fr 1fr",
      // margin: "120px max(120px, 50% - 660px)",
      // gap: "25px",
      padding: 100,
      [theme.breakpoints.down("md")]: {
        display: "grid",
        gridTemplateColumns: "1fr",
        margin: "0",
        gap: "25px",
      },
      "& a": {
        width: 192,
        height: 52,
        background: "#000",
        padding: "10px 30px",
        borderRadius: 2,
        color: "#fff",
      },
    },
    cardEventTitle: {
      font: "normal normal normal 100px/100px Android-101",
    },

    cardEventDetail: {
      marginTop: 40,
      display: "flex",
      width: "100%",
    },

    cardEventDetailLeft: {
      display: "inline-flex",
      width: "65%",
    },

    cardEventDetailItem: {
      width: "33%",
      display: "inline-block",
    },

    cardEventDetailRight: {
      fontSize: 32,
      textAlign: "center",
      lingHeight: "36px",
      width: "35%",
      fontWeight: 600,
      display: "inline",
    },

    howJoin: {
      width: "100%",
      height: "100%",
      // maxWidth: "720px",
      //height: "100%",
      paddingRight: 100,
      background: "#F1BA40",
      display: "flex",
      margin: "0 auto",

      "& .special": {
        width: 180,
        borderRadius: 4,
        background: "#010142",
        color: "#fff",
        font: "normal normal bold 14px/21px Poppins",
        letterSpacing: 1,
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "6px 0",
        marginBottom: 37,
      },

      "& .join-guide": {
        marginTop: 20,
        whiteSpace: "pre-line",
        font: "normal normal normal 18px/28px Roboto-Regular",
        color: "#00004F",
      },

      "& .view-details": {
        font: "normal normal 500 18px/24px Poppins",
        textDecorationLine: "underline",
        color: "#00004F",
      },

      "& a": {
        display: "flex",
        alignItems: "center",
        marginTop: 43,
      },
    },

    whoJoin: {
      width: "100%",
      // maxWidth: "720px",
      //maxWidth: "720px",
      paddingLeft: 100,
      background: `url(${bgWhoJoin})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "flex",
      margin: "0 auto",

      "& .special": {
        width: 180,
        borderRadius: 4,
        background: "#F1BA40",
        color: "#00004F",
        font: "normal normal bold 14px/21px Poppins",
        letterSpacing: 1,
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "6px 0",
      },

      "& .text-join": {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontStyle: "normal",
        maxWidth: 350,
      },
      "& h1": {
        fontSize: 40,
        lineHeight: "48px",
      },
      "& h2": {
        fontSize: 28,
        lineHeight: "36px",
      },
    },

    buyTicketFooter: {
      width: "100%",
      height: 130,
      display: "flex",
      flexWrap: 'wrap',
    },
    buyTicketFooterRight: {
      display: "inline-block",
      width: "50%",
      padding: 15,
      background: `url(${buyTicketRight})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "left",
      backgroundSize: "65px 130px",
      backgroundColor: "#00007B",
      textAlign: "center",
      position: "relative",
      [theme.breakpoints.down('sm')]:{
        width: "100%",
      }
    },
    buyTicketFooterLeft: {
      paddingLeft: 240,
      paddingRight: 220,
      display: "inline-block",
      width: "50%",
      background: `url(${buyTicketLeft})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "right",
      backgroundSize: "200px 130px",
      [theme.breakpoints.down('sm')]:{
        paddingRight: 20,
        paddingLeft: 20,
        width: "100%",
      }
    },

    smallButton: {
      width: 140,
      height: 40,
      padding: "10px 24px",
      fontSize: 16,
      lineHeight: "20px",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Poppins",
      marginLeft: "auto",
    },

    flexCol: {
      display: "flex",
      flexDirection: "column",
    },

    flexRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: 'wrap'
    },

    buyTokenInput: {
      border: "none",
      color: "#000",
      fontFamily: "Roboto-Regular",
      fontSize: 14,
      lineHeight: "20px",
      borderRadius: 2,
      height: 48,
      background: "#fff",
      paddingLeft: 17,
      paddingRight: 17,
      margin: "6px 0 12px 0",

      "&:focus": {
        outline: "none",
        fontFamily: "Roboto-Regular",
      },

      "&::placeholder": {
        fontFamily: "Roboto-Regular",
        fontSize: 16,
        lineHeight: "24px",
        color: "#666666",
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  };
});

export default useStyles;
