import { makeStyles } from "@material-ui/core";
const iconGoto = "/images/banner_background.png";


const useStyles = makeStyles((theme: any) => {
  return {
    bannerOverlay: {
      background: `#00000090`,
      position: 'absolute',
      zIndex: 99,
      width: '100%',
      height: '100%',
    },
    bannerContainer: {
      background: `url(${iconGoto})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      // boxShadow: '2px px #ffffff20',
      width: '80%',
      maxWidth: 800,
      borderRadius: 16,
      textAlign: 'center',
      position: 'absolute',
      zIndex: 100,
      padding: 50,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',

    },
    bannerCountDown: {
      background: '#ffffff16',
      borderRadius: 12,
      width: 'fit-content',
      margin: '0 auto',
      padding: '20px 100px',
      color: '#F8DC4A',
      fontSize: 32,
      fontWeight: 500,
      marginTop: 20
    },
    bannerSubtitle: {
      color: '#F8DC4A',
      fontSize: 30,
      fontWeight: 400,
      lineHeight: '30px',
      marginTop: 20,
    },
    bannerTitle: {
      color: '#FFFFFF',
      fontSize: 64,
      fontWeight: 700,
      lineHeight: '80px',
      marginTop: 20,
    },
    bannerClose: {
      position: 'absolute',
      width: 30,
      height: 30,
      cursor: 'pointer',
      top: 20,
      padding: 5,
      right: 20,
      background: '#ffffff80',
      textAlign: 'center',
      borderRadius: 20,
      zIndex: 101
    }
  };
});

export default useStyles;
