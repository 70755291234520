import React, {useEffect, useState} from "react";
import useStyles from "./style";
import {useCommonStyle} from "../../../styles";
import {withRouter} from "react-router-dom";
// @ts-ignore
import ReactPaginate from 'react-paginate';
import NumberFormat from "react-number-format";


const Winner = (props: any) => {
    const styles = useStyles();
    const commonStyle = useCommonStyle();
    const [currentDrawWinner, setCurrentDrawWinner] = useState(1);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageItem, setPageItem] = useState([]);
    const winners = [[
        {address : "0x09627c8275439C827280a4d5b7E7059cF54ED902", reward : "$ 40.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 40.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 40.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 40.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 40.00 Prize"},
        {address : "0x5B4AAedc822Cabb8FB56F8a402401519268a6ef4", reward : "$ 40.00 Prize"},
        {address : "0x5FA788c1Ff5781C406B65d32efE7E22201989Bad", reward : "$ 40.00 Prize"},
        {address : "0x6A94FB4063a7cf08aFDfCF63181d47Fea9fc2558", reward : "$ 40.00 Prize"},
        {address : "0x7dcb8b9a6fd2678fca952fb6ad10594adc4c0a92", reward : "$ 40.00 Prize"},
        {address : "0x7dcb8b9a6fd2678fca952fb6ad10594adc4c0a92", reward : "$ 40.00 Prize"},
        {address : "0x7dcb8b9a6fd2678fca952fb6ad10594adc4c0a92", reward : "$ 40.00 Prize"},
        {address : "0x7ff7c8a4B74a5dA6e8d1a8d1991d9a8CBd6C4335", reward : "$ 40.00 Prize"},
        {address : "0x7ff7c8a4B74a5dA6e8d1a8d1991d9a8CBd6C4335", reward : "$ 40.00 Prize"},
        {address : "0x831142c67494cc3468861f5d0e5b2729f13835a7", reward : "$ 40.00 Prize"},
        {address : "0x8bc4150FD51bb5c41c9971b1C32BEaa685C98Ba7", reward : "$ 40.00 Prize"},
        {address : "0x9ffB8Eb2a55ed17a7c86070c238563a0c3415e41", reward : "$ 40.00 Prize"},
        {address : "0xBA1b4b59F3C9273d7c9a4d956CE24BF8E21b0CD1", reward : "$ 40.00 Prize"},
        {address : "0xcD0Cd71D14bd3B9e36284BfFf42ED53290654185", reward : "$ 40.00 Prize"},
        {address : "0xD68fd20b2e4B107B8ea411B155761bb0184b4534", reward : "$ 40.00 Prize"},
        {address : "0xD6db6470319806Dd4d49003291650cc16357e70A", reward : "$ 40.00 Prize"},
        {address : "0xd7A54bB613c5aa04539E6aa71C906Ae12fAD9bdb", reward : "$ 40.00 Prize"},
        {address : "0xd7A54bB613c5aa04539E6aa71C906Ae12fAD9bdb", reward : "$ 40.00 Prize"},
        {address : "0xdCcF29fa0f10922F6284441193b0192d361101d4", reward : "$ 40.00 Prize"},
        {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 40.00 Prize"},
        {address : "0xe604d72554f992cbc40cb80728554d6b1cec2723", reward : "$ 40.00 Prize"},
        {address : "0x71612c5CA4bC288d199Ec0d3EDfE88e4399d39BE", reward : "$ 20.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 20.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 20.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 20.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 20.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 20.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 20.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 20.00 Prize"},
        {address : "0x0C48fA9566D777E713d5f19Cc689750d7b8EF0a2", reward : "$ 20.00 Prize"},
        {address : "0x1BD36C6d6c0a0635Af9e37EcdCaEFA5DF3Efd0D2", reward : "$ 20.00 Prize"},
        {address : "0x1BD36C6d6c0a0635Af9e37EcdCaEFA5DF3Efd0D2", reward : "$ 20.00 Prize"},
        {address : "0x1BD36C6d6c0a0635Af9e37EcdCaEFA5DF3Efd0D2", reward : "$ 20.00 Prize"},
        {address : "0x29b9c6dd7fC28dc16C675EA052bAe65A69bD9A9b", reward : "$ 20.00 Prize"},
        {address : "0x29b9c6dd7fC28dc16C675EA052bAe65A69bD9A9b", reward : "$ 20.00 Prize"},
        {address : "0x3C284A074afe106AdBc1B6Eeea1C15983eaFdC47", reward : "$ 20.00 Prize"},
        {address : "0x3Cefb490C551540f74fd67399cb3aCbDB034eAe7", reward : "$ 20.00 Prize"},
        {address : "0x5081220b0595a522D9707d66cE72515aECc60c0e", reward : "$ 20.00 Prize"},
        {address : "0x525132158d6966AD6BA7b729295a73616a8A06a8", reward : "$ 20.00 Prize"},
        {address : "0x5B4AAedc822Cabb8FB56F8a402401519268a6ef4", reward : "$ 20.00 Prize"},
        {address : "0x5b73163920f9090079f1b7bce7e01e149586961b", reward : "$ 20.00 Prize"},
        {address : "0x713c60d2Cc78b6a85E6c01e24e360756186BF73c", reward : "$ 20.00 Prize"},
        {address : "0x78BA63ED65BFEa0dC60c10E35DfF655963E46f6C", reward : "$ 20.00 Prize"},
        {address : "0x7aD7a26392a553AfA343f323eaB4E3125B22E6eA", reward : "$ 20.00 Prize"},
        {address : "0x7dcb8b9a6fd2678fca952fb6ad10594adc4c0a92", reward : "$ 20.00 Prize"},
        {address : "0x7dcb8b9a6fd2678fca952fb6ad10594adc4c0a92", reward : "$ 20.00 Prize"},
        {address : "0x7ff7c8a4B74a5dA6e8d1a8d1991d9a8CBd6C4335", reward : "$ 20.00 Prize"},
        {address : "0x8534c93a95eD79a0d0FC496fb4d8c19A6193f0a0", reward : "$ 20.00 Prize"},
        {address : "0x92cA471c5b7F377921795CcFE136d5B170ee4fD6", reward : "$ 20.00 Prize"},
        {address : "0x92cA471c5b7F377921795CcFE136d5B170ee4fD6", reward : "$ 20.00 Prize"},
        {address : "0x92cA471c5b7F377921795CcFE136d5B170ee4fD6", reward : "$ 20.00 Prize"},
        {address : "0x97150eAE436A0eE3B834bC96cb9d11BAB635cEFB", reward : "$ 20.00 Prize"},
        {address : "0x97150eAE436A0eE3B834bC96cb9d11BAB635cEFB", reward : "$ 20.00 Prize"},
        {address : "0x9ffB8Eb2a55ed17a7c86070c238563a0c3415e41", reward : "$ 20.00 Prize"},
        {address : "0x9ffB8Eb2a55ed17a7c86070c238563a0c3415e41", reward : "$ 20.00 Prize"},
        {address : "0xA1be4cD61B4C4eA218D9Ec66f6873E52100ccE56", reward : "$ 20.00 Prize"},
        {address : "0xA6B133935e4D9dE6A8C7a29F93D6dA400a365585", reward : "$ 20.00 Prize"},
        {address : "0xb6AF6b114CAb12B4b64426972d65f2846Da42e78", reward : "$ 20.00 Prize"},
        {address : "0xcD0Cd71D14bd3B9e36284BfFf42ED53290654185", reward : "$ 20.00 Prize"},
        {address : "0xcD0Cd71D14bd3B9e36284BfFf42ED53290654185", reward : "$ 20.00 Prize"},
        {address : "0xcD0Cd71D14bd3B9e36284BfFf42ED53290654185", reward : "$ 20.00 Prize"},
        {address : "0xD68fd20b2e4B107B8ea411B155761bb0184b4534", reward : "$ 20.00 Prize"},
        {address : "0xD68fd20b2e4B107B8ea411B155761bb0184b4534", reward : "$ 20.00 Prize"},
        {address : "0xD6db6470319806Dd4d49003291650cc16357e70A", reward : "$ 20.00 Prize"},
        {address : "0xd7A54bB613c5aa04539E6aa71C906Ae12fAD9bdb", reward : "$ 20.00 Prize"},
        {address : "0xDe6EAc012675cA5690387BE92C455c0Fc755E86e", reward : "$ 20.00 Prize"},
        {address : "0xE115582727502AB78aC2678A2E5aa4eDD42D7e86", reward : "$ 20.00 Prize"},
        {address : "0xe36e6E2FF6ed313A1Dc554c911d19920164A4093", reward : "$ 20.00 Prize"},
        {address : "0xf3551c7789C18eC12eD8088095cB8535De59f2b5", reward : "$ 20.00 Prize"},
        {address : "0xf85f2099ca465C056F85fCDd37E77C88BD25b7D6", reward : "$ 20.00 Prize"},
        {address : "0xf85f2099ca465C056F85fCDd37E77C88BD25b7D6", reward : "$ 20.00 Prize"},
        {address : "0x09627c8275439C827280a4d5b7E7059cF54ED902", reward : "$ 10.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
        {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
        {address : "0x0C48fA9566D777E713d5f19Cc689750d7b8EF0a2", reward : "$ 10.00 Prize"},
        {address : "0x0C48fA9566D777E713d5f19Cc689750d7b8EF0a2", reward : "$ 10.00 Prize"},
        {address : "0x1BD36C6d6c0a0635Af9e37EcdCaEFA5DF3Efd0D2", reward : "$ 10.00 Prize"},
        {address : "0x29b9c6dd7fC28dc16C675EA052bAe65A69bD9A9b", reward : "$ 10.00 Prize"},
        {address : "0x29b9c6dd7fC28dc16C675EA052bAe65A69bD9A9b", reward : "$ 10.00 Prize"},
        {address : "0x29b9c6dd7fC28dc16C675EA052bAe65A69bD9A9b", reward : "$ 10.00 Prize"},
        {address : "0x33927eD68c3853d193EdcF6Ab0F7732A61533B76", reward : "$ 10.00 Prize"},
        {address : "0x34a2700a1bcd80eedc0a21db0b46dac2b9b77478", reward : "$ 10.00 Prize"},
        {address : "0x3Cefb490C551540f74fd67399cb3aCbDB034eAe7", reward : "$ 10.00 Prize"},
        {address : "0x3Cefb490C551540f74fd67399cb3aCbDB034eAe7", reward : "$ 10.00 Prize"},
        {address : "0x5081220b0595a522D9707d66cE72515aECc60c0e", reward : "$ 10.00 Prize"},
        {address : "0x5B4AAedc822Cabb8FB56F8a402401519268a6ef4", reward : "$ 10.00 Prize"},
        {address : "0x5b73163920f9090079f1b7bce7e01e149586961b", reward : "$ 10.00 Prize"},
        {address : "0x5b73163920f9090079f1b7bce7e01e149586961b", reward : "$ 10.00 Prize"},
        {address : "0x5FA788c1Ff5781C406B65d32efE7E22201989Bad", reward : "$ 10.00 Prize"},
        {address : "0x5FA788c1Ff5781C406B65d32efE7E22201989Bad", reward : "$ 10.00 Prize"},
        {address : "0x6A94FB4063a7cf08aFDfCF63181d47Fea9fc2558", reward : "$ 10.00 Prize"},
        {address : "0x6FEBc32b23F5A9F110B2d9E6F4a7D1e6125ee206", reward : "$ 10.00 Prize"},
        {address : "0x713c60d2Cc78b6a85E6c01e24e360756186BF73c", reward : "$ 10.00 Prize"},
        {address : "0x713c60d2Cc78b6a85E6c01e24e360756186BF73c", reward : "$ 10.00 Prize"},
        {address : "0x713c60d2Cc78b6a85E6c01e24e360756186BF73c", reward : "$ 10.00 Prize"},
        {address : "0x713c60d2Cc78b6a85E6c01e24e360756186BF73c", reward : "$ 10.00 Prize"},
        {address : "0x751D73E1fa608F9b80227A5F6a3E66F2EDA49d60", reward : "$ 10.00 Prize"},
        {address : "0x78BA63ED65BFEa0dC60c10E35DfF655963E46f6C", reward : "$ 10.00 Prize"},
        {address : "0x78BA63ED65BFEa0dC60c10E35DfF655963E46f6C", reward : "$ 10.00 Prize"},
        {address : "0x78BA63ED65BFEa0dC60c10E35DfF655963E46f6C", reward : "$ 10.00 Prize"},
        {address : "0x78fFf3D4AE1210809d1c03d71AEA16057c3b119d", reward : "$ 10.00 Prize"},
        {address : "0x78fFf3D4AE1210809d1c03d71AEA16057c3b119d", reward : "$ 10.00 Prize"},
        {address : "0x78fFf3D4AE1210809d1c03d71AEA16057c3b119d", reward : "$ 10.00 Prize"},
        {address : "0x7aD7a26392a553AfA343f323eaB4E3125B22E6eA", reward : "$ 10.00 Prize"},
        {address : "0x7aD7a26392a553AfA343f323eaB4E3125B22E6eA", reward : "$ 10.00 Prize"},
        {address : "0x7aD7a26392a553AfA343f323eaB4E3125B22E6eA", reward : "$ 10.00 Prize"},
        {address : "0x7dcb8b9a6fd2678fca952fb6ad10594adc4c0a92", reward : "$ 10.00 Prize"},
        {address : "0x7dcb8b9a6fd2678fca952fb6ad10594adc4c0a92", reward : "$ 10.00 Prize"},
        {address : "0x7dcb8b9a6fd2678fca952fb6ad10594adc4c0a92", reward : "$ 10.00 Prize"},
        {address : "0x7dcb8b9a6fd2678fca952fb6ad10594adc4c0a92", reward : "$ 10.00 Prize"},
        {address : "0x7dcb8b9a6fd2678fca952fb6ad10594adc4c0a92", reward : "$ 10.00 Prize"},
        {address : "0x7ff7c8a4B74a5dA6e8d1a8d1991d9a8CBd6C4335", reward : "$ 10.00 Prize"},
        {address : "0x831142c67494cc3468861f5d0e5b2729f13835a7", reward : "$ 10.00 Prize"},
        {address : "0x8bc4150FD51bb5c41c9971b1C32BEaa685C98Ba7", reward : "$ 10.00 Prize"},
        {address : "0x92cA471c5b7F377921795CcFE136d5B170ee4fD6", reward : "$ 10.00 Prize"},
        {address : "0x92cA471c5b7F377921795CcFE136d5B170ee4fD6", reward : "$ 10.00 Prize"},
        {address : "0x92cA471c5b7F377921795CcFE136d5B170ee4fD6", reward : "$ 10.00 Prize"},
        {address : "0x97150eAE436A0eE3B834bC96cb9d11BAB635cEFB", reward : "$ 10.00 Prize"},
        {address : "0x97150eAE436A0eE3B834bC96cb9d11BAB635cEFB", reward : "$ 10.00 Prize"},
        {address : "0x9ffB8Eb2a55ed17a7c86070c238563a0c3415e41", reward : "$ 10.00 Prize"},
        {address : "0x9ffB8Eb2a55ed17a7c86070c238563a0c3415e41", reward : "$ 10.00 Prize"},
        {address : "0xA1be4cD61B4C4eA218D9Ec66f6873E52100ccE56", reward : "$ 10.00 Prize"},
        {address : "0xA1be4cD61B4C4eA218D9Ec66f6873E52100ccE56", reward : "$ 10.00 Prize"},
        {address : "0xA1be4cD61B4C4eA218D9Ec66f6873E52100ccE56", reward : "$ 10.00 Prize"},
        {address : "0xA1be4cD61B4C4eA218D9Ec66f6873E52100ccE56", reward : "$ 10.00 Prize"},
        {address : "0xA4A4dB7b1dEBDF4E6C9AFE7Bf65b45AA56b03D88", reward : "$ 10.00 Prize"},
        {address : "0xA6B133935e4D9dE6A8C7a29F93D6dA400a365585", reward : "$ 10.00 Prize"},
        {address : "0xb8a960a8A1d2120B5F00AA74eb49524cafa284B9", reward : "$ 10.00 Prize"},
        {address : "0xb8a960a8A1d2120B5F00AA74eb49524cafa284B9", reward : "$ 10.00 Prize"},
        {address : "0xBA1b4b59F3C9273d7c9a4d956CE24BF8E21b0CD1", reward : "$ 10.00 Prize"},
        {address : "0xcD0Cd71D14bd3B9e36284BfFf42ED53290654185", reward : "$ 10.00 Prize"},
        {address : "0xd537A2b2b97d104F2D9C7a84377Fc11573629085", reward : "$ 10.00 Prize"},
        {address : "0xd537A2b2b97d104F2D9C7a84377Fc11573629085", reward : "$ 10.00 Prize"},
        {address : "0xd537A2b2b97d104F2D9C7a84377Fc11573629085", reward : "$ 10.00 Prize"},
        {address : "0xd5c08316589f1cf29647d58bb6e790bd905c9a93", reward : "$ 10.00 Prize"},
        {address : "0xD68fd20b2e4B107B8ea411B155761bb0184b4534", reward : "$ 10.00 Prize"},
        {address : "0xD68fd20b2e4B107B8ea411B155761bb0184b4534", reward : "$ 10.00 Prize"},
        {address : "0xD6db6470319806Dd4d49003291650cc16357e70A", reward : "$ 10.00 Prize"},
        {address : "0xD6db6470319806Dd4d49003291650cc16357e70A", reward : "$ 10.00 Prize"},
        {address : "0xD6db6470319806Dd4d49003291650cc16357e70A", reward : "$ 10.00 Prize"},
        {address : "0xDe6EAc012675cA5690387BE92C455c0Fc755E86e", reward : "$ 10.00 Prize"},
        {address : "0xE115582727502AB78aC2678A2E5aa4eDD42D7e86", reward : "$ 10.00 Prize"},
        {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 10.00 Prize"},
        {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 10.00 Prize"},
        {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 10.00 Prize"},
        {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 10.00 Prize"},
        {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 10.00 Prize"},
        {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 10.00 Prize"},
        {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 10.00 Prize"},
        {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 10.00 Prize"},
        {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 10.00 Prize"},
        {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 10.00 Prize"},
        {address : "0xe36e6E2FF6ed313A1Dc554c911d19920164A4093", reward : "$ 10.00 Prize"},
        {address : "0xe5Da8abBB4C9B00aAaa0F69f9ba0101a5e3D914C", reward : "$ 10.00 Prize"},
        {address : "0xe604d72554f992cbc40cb80728554d6b1cec2723", reward : "$ 10.00 Prize"},
        {address : "0xe604d72554f992cbc40cb80728554d6b1cec2723", reward : "$ 10.00 Prize"},
        {address : "0xe604d72554f992cbc40cb80728554d6b1cec2723", reward : "$ 10.00 Prize"},
        {address : "0xe604d72554f992cbc40cb80728554d6b1cec2723", reward : "$ 10.00 Prize"},
        {address : "0xe604d72554f992cbc40cb80728554d6b1cec2723", reward : "$ 10.00 Prize"},
        {address : "0xe604d72554f992cbc40cb80728554d6b1cec2723", reward : "$ 10.00 Prize"},
        {address : "0xf85f2099ca465C056F85fCDd37E77C88BD25b7D6", reward : "$ 10.00 Prize"}
        ],
        [
            {address : "0x63173476E9d45aFa32BAe3538Ba7bA6b685C27f2", reward : "$ 50.00 Prize"},
            {address : "0x6A94FB4063a7cf08aFDfCF63181d47Fea9fc2558", reward : "$ 50.00 Prize"},
            {address : "0xf3551c7789C18eC12eD8088095cB8535De59f2b5", reward : "$ 50.00 Prize"},
            {address : "0x00fBab65D200E11164c67C6Df8f68af2472dC34e", reward : "$ 50.00 Prize"},
            {address : "0xb6AF6b114CAb12B4b64426972d65f2846Da42e78", reward : "$ 50.00 Prize"},
            {address : "0x56f38ac905cb25ac3079c24a406d0dc0d99c6913", reward : "$ 50.00 Prize"},
            {address : "0x85ce14796802916793f7903d0711e607e647287a", reward : "$ 50.00 Prize"},
            {address : "0x4579ac723c85855f0cd945a41d4d8a70aba589a3", reward : "$ 50.00 Prize"},
            {address : "0x4579ac723c85855f0cd945a41d4d8a70aba589a3", reward : "$ 50.00 Prize"},
            {address : "0xf321ed95bbd3776a7c7d6592d989fae98eae4560", reward : "$ 50.00 Prize"},
            {address : "0xd7B821Ff90C4b4f9a56E93Bd6efe81bf65b439a1", reward : "$ 40.00 Prize"},
            {address : "0xD68fd20b2e4B107B8ea411B155761bb0184b4534", reward : "$ 40.00 Prize"},
            {address : "0x7ff7c8a4B74a5dA6e8d1a8d1991d9a8CBd6C4335", reward : "$ 40.00 Prize"},
            {address : "0x00fBab65D200E11164c67C6Df8f68af2472dC34e", reward : "$ 40.00 Prize"},
            {address : "0x9ffB8Eb2a55ed17a7c86070c238563a0c3415e41", reward : "$ 40.00 Prize"},
            {address : "0x9ffB8Eb2a55ed17a7c86070c238563a0c3415e41", reward : "$ 40.00 Prize"},
            {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 40.00 Prize"},
            {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 40.00 Prize"},
            {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 40.00 Prize"},
            {address : "0xf85f2099ca465C056F85fCDd37E77C88BD25b7D6", reward : "$ 40.00 Prize"},
            {address : "0x78fFf3D4AE1210809d1c03d71AEA16057c3b119d", reward : "$ 40.00 Prize"},
            {address : "0x8bc4150FD51bb5c41c9971b1C32BEaa685C98Ba7", reward : "$ 40.00 Prize"},
            {address : "0xDab6AdACCaf60960E9B9C0c32C3C8C564F2db890", reward : "$ 40.00 Prize"},
            {address : "0x7dcb8b9a6fd2678fca952fb6ad10594adc4c0a92", reward : "$ 40.00 Prize"},
            {address : "0x66ecaacb7de289da8f86c05624f12cd82a3ea656", reward : "$ 40.00 Prize"},
            {address : "0x20e82da62cb356d2c73aac73c2d7036c74e84619", reward : "$ 40.00 Prize"},
            {address : "0xdc8d28503eee6fe5a1ec51f4cf55d9b2633c359e", reward : "$ 40.00 Prize"},
            {address : "0x23f39770810a68283581acc62b0f0f5c089a8e8c", reward : "$ 40.00 Prize"},
            {address : "0x23f39770810a68283581acc62b0f0f5c089a8e8c", reward : "$ 40.00 Prize"},
            {address : "0xf1600eb68730bc980bbdb30d20d8676c85a050ee", reward : "$ 40.00 Prize"},
            {address : "0xe64ca4df2e05880c2b0ee33c1b5bd8b86b31c3a4", reward : "$ 40.00 Prize"},
            {address : "0xeee1cbb4abcd02744e936ff50c7cb9026c1f7dc5", reward : "$ 40.00 Prize"},
            {address : "0xdea447c2ceb745ddfea4a207b4b03d807c4b3b4e", reward : "$ 40.00 Prize"},
            {address : "0x4579ac723c85855f0cd945a41d4d8a70aba589a3", reward : "$ 40.00 Prize"},
            {address : "0xf321ed95bbd3776a7c7d6592d989fae98eae4560", reward : "$ 40.00 Prize"},
            {address : "0x7ff7c8a4B74a5dA6e8d1a8d1991d9a8CBd6C4335", reward : "$ 20.00 Prize"},
            {address : "0x7ff7c8a4B74a5dA6e8d1a8d1991d9a8CBd6C4335", reward : "$ 20.00 Prize"},
            {address : "0x6A94FB4063a7cf08aFDfCF63181d47Fea9fc2558", reward : "$ 20.00 Prize"},
            {address : "0x00fBab65D200E11164c67C6Df8f68af2472dC34e", reward : "$ 20.00 Prize"},
            {address : "0x00fBab65D200E11164c67C6Df8f68af2472dC34e", reward : "$ 20.00 Prize"},
            {address : "0x00fBab65D200E11164c67C6Df8f68af2472dC34e", reward : "$ 20.00 Prize"},
            {address : "0x00fBab65D200E11164c67C6Df8f68af2472dC34e", reward : "$ 20.00 Prize"},
            {address : "0x9ffB8Eb2a55ed17a7c86070c238563a0c3415e41", reward : "$ 20.00 Prize"},
            {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 20.00 Prize"},
            {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 20.00 Prize"},
            {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 20.00 Prize"},
            {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 20.00 Prize"},
            {address : "0x33927eD68c3853d193EdcF6Ab0F7732A61533B76", reward : "$ 20.00 Prize"},
            {address : "0xcD0Cd71D14bd3B9e36284BfFf42ED53290654185", reward : "$ 20.00 Prize"},
            {address : "0x78BA63ED65BFEa0dC60c10E35DfF655963E46f6C", reward : "$ 20.00 Prize"},
            {address : "0xE115582727502AB78aC2678A2E5aa4eDD42D7e86", reward : "$ 20.00 Prize"},
            {address : "0xf85f2099ca465C056F85fCDd37E77C88BD25b7D6", reward : "$ 20.00 Prize"},
            {address : "0xf85f2099ca465C056F85fCDd37E77C88BD25b7D6", reward : "$ 20.00 Prize"},
            {address : "0xf85f2099ca465C056F85fCDd37E77C88BD25b7D6", reward : "$ 20.00 Prize"},
            {address : "0xf85f2099ca465C056F85fCDd37E77C88BD25b7D6", reward : "$ 20.00 Prize"},
            {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 20.00 Prize"},
            {address : "0xbbE50f03585F0E44aAC6Bd7cb174520DE72480aC", reward : "$ 20.00 Prize"},
            {address : "0xbbE50f03585F0E44aAC6Bd7cb174520DE72480aC", reward : "$ 20.00 Prize"},
            {address : "0xb6AF6b114CAb12B4b64426972d65f2846Da42e78", reward : "$ 20.00 Prize"},
            {address : "0xDab6AdACCaf60960E9B9C0c32C3C8C564F2db890", reward : "$ 20.00 Prize"},
            {address : "0x02272f4c45d790488f06a339acac0c94906995f0", reward : "$ 20.00 Prize"},
            {address : "0xe5d4e9cd4bc63c2039bce62564fcaa2a94e2613e", reward : "$ 20.00 Prize"},
            {address : "0x51c3f2d273c78a7aeb41c0103c47f69fec7be280", reward : "$ 20.00 Prize"},
            {address : "0xdc8d28503eee6fe5a1ec51f4cf55d9b2633c359e", reward : "$ 20.00 Prize"},
            {address : "0x131c5c77450268b7e77e76ddccc6769fc36c9356", reward : "$ 20.00 Prize"},
            {address : "0xaa2ece0109c444e8bbcd23ec748ad4a7b8a401f2", reward : "$ 20.00 Prize"},
            {address : "0x16ab25cf7f2ee384418e1167e7f329860ac98a69", reward : "$ 20.00 Prize"},
            {address : "0xbe075bc224998347649d147ac6178272b9a681e2", reward : "$ 20.00 Prize"},
            {address : "0x56f38ac905cb25ac3079c24a406d0dc0d99c6913", reward : "$ 20.00 Prize"},
            {address : "0x56f38ac905cb25ac3079c24a406d0dc0d99c6913", reward : "$ 20.00 Prize"},
            {address : "0x5891dd6347719a7e53ccae91cc8f87b91a23f7f2", reward : "$ 20.00 Prize"},
            {address : "0xb421e36024bfb897d705272896910a5815c322db", reward : "$ 20.00 Prize"},
            {address : "0xf1600eb68730bc980bbdb30d20d8676c85a050ee", reward : "$ 20.00 Prize"},
            {address : "0xd18992db12494f18f0fd2e4a05c1596aced9131e", reward : "$ 20.00 Prize"},
            {address : "0xcc46a60683acfa89b509cb5bf0f2f311a39a8c53", reward : "$ 20.00 Prize"},
            {address : "0xda366245eb5c1de4ed27b799f6a5bbbee1f097b0", reward : "$ 20.00 Prize"},
            {address : "0x36721cb34c08054a1ccb7199e21ef15f74105196", reward : "$ 20.00 Prize"},
            {address : "0x8cce978afe0e83c5967744c708cbd2449022c08f", reward : "$ 20.00 Prize"},
            {address : "0xd26708aa4a5527993c370d7fe8787620ae8c4778", reward : "$ 20.00 Prize"},
            {address : "0x6b04b505de68dfa59f6753c39a6a77d35fb7759b", reward : "$ 20.00 Prize"},
            {address : "0xdea447c2ceb745ddfea4a207b4b03d807c4b3b4e", reward : "$ 20.00 Prize"},
            {address : "0x4579ac723c85855f0cd945a41d4d8a70aba589a3", reward : "$ 20.00 Prize"},
            {address : "0x4579ac723c85855f0cd945a41d4d8a70aba589a3", reward : "$ 20.00 Prize"},
            {address : "0x4579ac723c85855f0cd945a41d4d8a70aba589a3", reward : "$ 20.00 Prize"},
            {address : "0x4b3ed5bba5913b4081a42819c9dd9de183fa0d83", reward : "$ 20.00 Prize"},
            {address : "0xd7B821Ff90C4b4f9a56E93Bd6efe81bf65b439a1", reward : "$ 10.00 Prize"},
            {address : "0xd7B821Ff90C4b4f9a56E93Bd6efe81bf65b439a1", reward : "$ 10.00 Prize"},
            {address : "0x1BD36C6d6c0a0635Af9e37EcdCaEFA5DF3Efd0D2", reward : "$ 10.00 Prize"},
            {address : "0xb8a960a8A1d2120B5F00AA74eb49524cafa284B9", reward : "$ 10.00 Prize"},
            {address : "0xb8a960a8A1d2120B5F00AA74eb49524cafa284B9", reward : "$ 10.00 Prize"},
            {address : "0x6A94FB4063a7cf08aFDfCF63181d47Fea9fc2558", reward : "$ 10.00 Prize"},
            {address : "0xA4A4dB7b1dEBDF4E6C9AFE7Bf65b45AA56b03D88", reward : "$ 10.00 Prize"},
            {address : "0x00fBab65D200E11164c67C6Df8f68af2472dC34e", reward : "$ 10.00 Prize"},
            {address : "0x00fBab65D200E11164c67C6Df8f68af2472dC34e", reward : "$ 10.00 Prize"},
            {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
            {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
            {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
            {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
            {address : "0x097E994F80195fAC98E2Eb9bA91140f4cEC88af7", reward : "$ 10.00 Prize"},
            {address : "0xcD0Cd71D14bd3B9e36284BfFf42ED53290654185", reward : "$ 10.00 Prize"},
            {address : "0xcD0Cd71D14bd3B9e36284BfFf42ED53290654185", reward : "$ 10.00 Prize"},
            {address : "0xE115582727502AB78aC2678A2E5aa4eDD42D7e86", reward : "$ 10.00 Prize"},
            {address : "0xf85f2099ca465C056F85fCDd37E77C88BD25b7D6", reward : "$ 10.00 Prize"},
            {address : "0xe12C72B86e2a13DFD7074B57c5322668D0D31e77", reward : "$ 10.00 Prize"},
            {address : "0x0C48fA9566D777E713d5f19Cc689750d7b8EF0a2", reward : "$ 10.00 Prize"},
            {address : "0xBBD8DA6f7a8EE975F3Cb1cBE8c314A778De38E5B", reward : "$ 10.00 Prize"},
            {address : "0xbbE50f03585F0E44aAC6Bd7cb174520DE72480aC", reward : "$ 10.00 Prize"},
            {address : "0xa70f9174AFdFd565dCf4723Ec5e3d5FE335Db5b0", reward : "$ 10.00 Prize"},
            {address : "0x5de70eb2e26c4e994bd28d467ec699dd045b2674", reward : "$ 10.00 Prize"},
            {address : "0xebea04c0235251380fcd5dcfb5e5d8b9f4205500", reward : "$ 10.00 Prize"},
            {address : "0xf82d38a79d44fcc19a7cdaf6a8393c9dbbe7e962", reward : "$ 10.00 Prize"},
            {address : "0x2c21e83a76b2dea9fb85885257eb1ac3f82da450", reward : "$ 10.00 Prize"},
            {address : "0x6f7c7a9597119372711e317a6f3caf945b3731d7", reward : "$ 10.00 Prize"},
            {address : "0xc15e1fa990b4140878f5b4e96368b3fe5149bc89", reward : "$ 10.00 Prize"},
            {address : "0x30742bde8a9ab2126f3389a40567ffbab2c6fe0c", reward : "$ 10.00 Prize"},
            {address : "0x16ab25cf7f2ee384418e1167e7f329860ac98a69", reward : "$ 10.00 Prize"},
            {address : "0xbe075bc224998347649d147ac6178272b9a681e2", reward : "$ 10.00 Prize"},
            {address : "0x72872adaea835f855d6303c130c7531208a76642", reward : "$ 10.00 Prize"},
            {address : "0x56f38ac905cb25ac3079c24a406d0dc0d99c6913", reward : "$ 10.00 Prize"},
            {address : "0x56f38ac905cb25ac3079c24a406d0dc0d99c6913", reward : "$ 10.00 Prize"},
            {address : "0xb421e36024bfb897d705272896910a5815c322db", reward : "$ 10.00 Prize"},
            {address : "0xe64ca4df2e05880c2b0ee33c1b5bd8b86b31c3a4", reward : "$ 10.00 Prize"},
            {address : "0xcacc99ca93885e50a1b6c64d8da959dc604cc74b", reward : "$ 10.00 Prize"},
            {address : "0xcacc99ca93885e50a1b6c64d8da959dc604cc74b", reward : "$ 10.00 Prize"},
            {address : "0xaafcdf5585e8d561689859f52bc55193a261bd54", reward : "$ 10.00 Prize"},
            {address : "0x36721cb34c08054a1ccb7199e21ef15f74105196", reward : "$ 10.00 Prize"},
            {address : "0xac75717dad88ff4115448ccb26667ecbaf5497d7", reward : "$ 10.00 Prize"},
            {address : "0x30661431d04982a4bc736bfaab828454265443be", reward : "$ 10.00 Prize"},
            {address : "0x85ce14796802916793f7903d0711e607e647287a", reward : "$ 10.00 Prize"},
            {address : "0x13ce389353dda15b3768a9e43b147d60e5999fd4", reward : "$ 10.00 Prize"},
            {address : "0x4579ac723c85855f0cd945a41d4d8a70aba589a3", reward : "$ 10.00 Prize"},
            {address : "0x4579ac723c85855f0cd945a41d4d8a70aba589a3", reward : "$ 10.00 Prize"},
            {address : "0x4579ac723c85855f0cd945a41d4d8a70aba589a3", reward : "$ 10.00 Prize"},
            {address : "0x4579ac723c85855f0cd945a41d4d8a70aba589a3", reward : "$ 10.00 Prize"},
            {address : "0xf321ed95bbd3776a7c7d6592d989fae98eae4560", reward : "$ 10.00 Prize"},
        ],[
            {address : "0x7dcb8b9a6fd2678fca952fb6ad10594adc4c0a92", reward : "$ 100.00 Prize"},
            {address : "0xb6af6b114cab12b4b64426972d65f2846da42e78", reward : "$ 100.00 Prize"},
            {address : "0x3673cf19b6efb296e29d9b07666e9625ed63cfe1", reward : "$ 100.00 Prize"},
            {address : "0x35bd8384a9292abe3b588b940b5ed76deedbe875", reward : "$ 100.00 Prize"},
            {address : "0xf977664c07ff445b82dcc85372dc93bcff065bc6", reward : "$ 100.00 Prize"},
            {address : "0xebea04c0235251380fcd5dcfb5e5d8b9f4205500", reward : "$ 50.00 Prize"},
            {address : "0xbe075bc224998347649d147ac6178272b9a681e2", reward : "$ 50.00 Prize"},
            {address : "0x3673cf19b6efb296e29d9b07666e9625ed63cfe1", reward : "$ 50.00 Prize"},
            {address : "0x3673cf19b6efb296e29d9b07666e9625ed63cfe1", reward : "$ 50.00 Prize"},
            {address : "0xe64ca4df2e05880c2b0ee33c1b5bd8b86b31c3a4", reward : "$ 50.00 Prize"},
            {address : "0x59699cdbf6519042d6074fdbe2408ad993435bd8", reward : "$ 50.00 Prize"},
            {address : "0xf977664c07ff445b82dcc85372dc93bcff065bc6", reward : "$ 50.00 Prize"},
            {address : "0x3506e5f79754e8aad83152b8e6189b6c5508aad4", reward : "$ 50.00 Prize"},
            {address : "0x9100d85f8edecfb68a995732793c6129aed92d52", reward : "$ 50.00 Prize"},
            {address : "0x3d127774339a9db809a0bea58526b1c00a744ebd", reward : "$ 50.00 Prize"},
            {address : "0xd68fd20b2e4b107b8ea411b155761bb0184b4534", reward : "$ 40.00 Prize"},
            {address : "0x9623f3a8a91d3d05756b07d948c595ddc0cb80c9", reward : "$ 40.00 Prize"},
            {address : "0x29b9c6dd7fc28dc16c675ea052bae65a69bd9a9b", reward : "$ 40.00 Prize"},
            {address : "0xa6b133935e4d9de6a8c7a29f93d6da400a365585", reward : "$ 40.00 Prize"},
            {address : "0x7ff7c8a4b74a5da6e8d1a8d1991d9a8cbd6c4335", reward : "$ 40.00 Prize"},
            {address : "0x63173476e9d45afa32bae3538ba7ba6b685c27f2", reward : "$ 40.00 Prize"},
            {address : "0xcd0cd71d14bd3b9e36284bfff42ed53290654185", reward : "$ 40.00 Prize"},
            {address : "0xac75717dad88ff4115448ccb26667ecbaf5497d7", reward : "$ 40.00 Prize"},
            {address : "0xd28126782135e94905956148c3de326c60b68734", reward : "$ 40.00 Prize"},
            {address : "0x6a94fb4063a7cf08afdfcf63181d47fea9fc2558", reward : "$ 40.00 Prize"},
            {address : "0x6a94fb4063a7cf08afdfcf63181d47fea9fc2558", reward : "$ 40.00 Prize"},
            {address : "0x96cb0c9fc8b78a730006632194ec0d34f2167cda", reward : "$ 40.00 Prize"},
            {address : "0x097e994f80195fac98e2eb9ba91140f4cec88af7", reward : "$ 40.00 Prize"},
            {address : "0x097e994f80195fac98e2eb9ba91140f4cec88af7", reward : "$ 40.00 Prize"},
            {address : "0x870e735ed7f9bf4a732a0ca01e23b6935cef3d18", reward : "$ 40.00 Prize"},
            {address : "0x7e1515e2a8f65c785eea5fd7ac31349d27e53947", reward : "$ 40.00 Prize"},
            {address : "0x59699cdbf6519042d6074fdbe2408ad993435bd8", reward : "$ 40.00 Prize"},
            {address : "0xa77d4a592017c7cc26511df8562417e80c4993fe", reward : "$ 40.00 Prize"},
            {address : "0xe7b617e837da88116b74b0e181c3babe6c0daf58", reward : "$ 40.00 Prize"},
            {address : "0xe12536773cf1c15653efbd8e91a637bfd390c897", reward : "$ 40.00 Prize"},
            {address : "0xeeba59a8b13ce6e206fbd2955e5a968b448239a7", reward : "$ 40.00 Prize"},
            {address : "0xcfef5bf5e5778fe33ed52d3c2bb5c29c324f0fd9", reward : "$ 40.00 Prize"},
            {address : "0xf3eab2365c8689d7d429e9cf03c78b29f07907b1", reward : "$ 40.00 Prize"},
            {address : "0x825af61919cf7dcc207317d71c9fcdc1f8bb2a9e", reward : "$ 40.00 Prize"},
            {address : "0xae12bf757b24372ac7979d6da0d642eeb74a5178", reward : "$ 40.00 Prize"},
            {address : "0x02272f4c45d790488f06a339acac0c94906995f0", reward : "$ 20.00 Prize"},
            {address : "0xaa2ece0109c444e8bbcd23ec748ad4a7b8a401f2", reward : "$ 20.00 Prize"},
            {address : "0xf85f2099ca465c056f85fcdd37e77c88bd25b7d6", reward : "$ 20.00 Prize"},
            {address : "0xde6eac012675ca5690387be92c455c0fc755e86e", reward : "$ 20.00 Prize"},
            {address : "0xbe075bc224998347649d147ac6178272b9a681e2", reward : "$ 20.00 Prize"},
            {address : "0x63173476e9d45afa32bae3538ba7ba6b685c27f2", reward : "$ 20.00 Prize"},
            {address : "0x56f38ac905cb25ac3079c24a406d0dc0d99c6913", reward : "$ 20.00 Prize"},
            {address : "0xcd0cd71d14bd3b9e36284bfff42ed53290654185", reward : "$ 20.00 Prize"},
            {address : "0xeee1cbb4abcd02744e936ff50c7cb9026c1f7dc5", reward : "$ 20.00 Prize"},
            {address : "0x5fa788c1ff5781c406b65d32efe7e22201989bad", reward : "$ 20.00 Prize"},
            {address : "0x6a94fb4063a7cf08afdfcf63181d47fea9fc2558", reward : "$ 20.00 Prize"},
            {address : "0x96cb0c9fc8b78a730006632194ec0d34f2167cda", reward : "$ 20.00 Prize"},
            {address : "0x097e994f80195fac98e2eb9ba91140f4cec88af7", reward : "$ 20.00 Prize"},
            {address : "0x097e994f80195fac98e2eb9ba91140f4cec88af7", reward : "$ 20.00 Prize"},
            {address : "0x59699cdbf6519042d6074fdbe2408ad993435bd8", reward : "$ 20.00 Prize"},
            {address : "0xa4a4db7b1debdf4e6c9afe7bf65b45aa56b03d88", reward : "$ 20.00 Prize"},
            {address : "0xa4a4db7b1debdf4e6c9afe7bf65b45aa56b03d88", reward : "$ 20.00 Prize"},
            {address : "0x5e98b3515aa22ef6cfb2789cc3f5dc75c4ea324c", reward : "$ 20.00 Prize"},
            {address : "0xcfef5bf5e5778fe33ed52d3c2bb5c29c324f0fd9", reward : "$ 20.00 Prize"},
            {address : "0x789991b8699ac05426e359411f5711b56864e8d2", reward : "$ 20.00 Prize"},
            {address : "0x825af61919cf7dcc207317d71c9fcdc1f8bb2a9e", reward : "$ 20.00 Prize"},
            {address : "0x8ff4e121827678b463e302400c88eb534d805850", reward : "$ 20.00 Prize"},
            {address : "0x3c439948c4967c3057ac8ac5c330c714b76ae788", reward : "$ 20.00 Prize"},
            {address : "0x3d127774339a9db809a0bea58526b1c00a744ebd", reward : "$ 20.00 Prize"},
            {address : "0x3d127774339a9db809a0bea58526b1c00a744ebd", reward : "$ 20.00 Prize"},
            {address : "0x927eae2338f00bb0b56709c6eada284dc412c0fc", reward : "$ 10.00 Prize"},
            {address : "0xcb39e6ab827135ecd2bde3d6d7a476831b4a6dd2", reward : "$ 10.00 Prize"},
            {address : "0xd68fd20b2e4b107b8ea411b155761bb0184b4534", reward : "$ 10.00 Prize"},
            {address : "0xb6af6b114cab12b4b64426972d65f2846da42e78", reward : "$ 10.00 Prize"},
            {address : "0x7ff7c8a4b74a5da6e8d1a8d1991d9a8cbd6c4335", reward : "$ 10.00 Prize"},
            {address : "0x7ff7c8a4b74a5da6e8d1a8d1991d9a8cbd6c4335", reward : "$ 10.00 Prize"},
            {address : "0x7ff7c8a4b74a5da6e8d1a8d1991d9a8cbd6c4335", reward : "$ 10.00 Prize"},
            {address : "0xde6eac012675ca5690387be92c455c0fc755e86e", reward : "$ 10.00 Prize"},
            {address : "0xde6eac012675ca5690387be92c455c0fc755e86e", reward : "$ 10.00 Prize"},
            {address : "0xbbe50f03585f0e44aac6bd7cb174520de72480ac", reward : "$ 10.00 Prize"},
            {address : "0xe12c72b86e2a13dfd7074b57c5322668d0d31e77", reward : "$ 10.00 Prize"},
            {address : "0x63173476e9d45afa32bae3538ba7ba6b685c27f2", reward : "$ 10.00 Prize"},
            {address : "0x3673cf19b6efb296e29d9b07666e9625ed63cfe1", reward : "$ 10.00 Prize"},
            {address : "0xd7b821ff90c4b4f9a56e93bd6efe81bf65b439a1", reward : "$ 10.00 Prize"},
            {address : "0x00fbab65d200e11164c67c6df8f68af2472dc34e", reward : "$ 10.00 Prize"},
            {address : "0x00fbab65d200e11164c67c6df8f68af2472dc34e", reward : "$ 10.00 Prize"},
            {address : "0xda366245eb5c1de4ed27b799f6a5bbbee1f097b0", reward : "$ 10.00 Prize"},
            {address : "0xda366245eb5c1de4ed27b799f6a5bbbee1f097b0", reward : "$ 10.00 Prize"},
            {address : "0x9ffb8eb2a55ed17a7c86070c238563a0c3415e41", reward : "$ 10.00 Prize"},
            {address : "0x9ffb8eb2a55ed17a7c86070c238563a0c3415e41", reward : "$ 10.00 Prize"},
            {address : "0x78ba63ed65bfea0dc60c10e35dff655963e46f6c", reward : "$ 10.00 Prize"},
            {address : "0xd28126782135e94905956148c3de326c60b68734", reward : "$ 10.00 Prize"},
            {address : "0x6a94fb4063a7cf08afdfcf63181d47fea9fc2558", reward : "$ 10.00 Prize"},
            {address : "0x097e994f80195fac98e2eb9ba91140f4cec88af7", reward : "$ 10.00 Prize"},
            {address : "0x097e994f80195fac98e2eb9ba91140f4cec88af7", reward : "$ 10.00 Prize"},
            {address : "0x097e994f80195fac98e2eb9ba91140f4cec88af7", reward : "$ 10.00 Prize"},
            {address : "0x097e994f80195fac98e2eb9ba91140f4cec88af7", reward : "$ 10.00 Prize"},
            {address : "0x097e994f80195fac98e2eb9ba91140f4cec88af7", reward : "$ 10.00 Prize"},
            {address : "0x7e1515e2a8f65c785eea5fd7ac31349d27e53947", reward : "$ 10.00 Prize"},
            {address : "0x7e1515e2a8f65c785eea5fd7ac31349d27e53947", reward : "$ 10.00 Prize"},
            {address : "0x59699cdbf6519042d6074fdbe2408ad993435bd8", reward : "$ 10.00 Prize"},
            {address : "0x1d08210b0a43a21a883734248d17a7b366ffb014", reward : "$ 10.00 Prize"},
            {address : "0x91c5029ca9a4e5c5687692520f7a7b130b619201", reward : "$ 10.00 Prize"},
            {address : "0x7ad7a26392a553afa343f323eab4e3125b22e6ea", reward : "$ 10.00 Prize"},
            {address : "0xe36e6e2ff6ed313a1dc554c911d19920164a4093", reward : "$ 10.00 Prize"},
            {address : "0xf977664c07ff445b82dcc85372dc93bcff065bc6", reward : "$ 10.00 Prize"},
            {address : "0x8a29d4e52a23c69ecc705bce3cca0d2b42faa23c", reward : "$ 10.00 Prize"},
            {address : "0x74028a31bb4f1c2e1b479c0c5f66f3e405827cd5", reward : "$ 10.00 Prize"},
            {address : "0x74028a31bb4f1c2e1b479c0c5f66f3e405827cd5", reward : "$ 10.00 Prize"},
            {address : "0x5dce57b1ae135943680be22db9b496fb1ffb3e58", reward : "$ 10.00 Prize"},
            {address : "0xc374a4034cb4380a982910372ea70c203c77d3a9", reward : "$ 10.00 Prize"},
            {address : "0x789991b8699ac05426e359411f5711b56864e8d2", reward : "$ 10.00 Prize"},
            {address : "0x789991b8699ac05426e359411f5711b56864e8d2", reward : "$ 10.00 Prize"},
            {address : "0x789991b8699ac05426e359411f5711b56864e8d2", reward : "$ 10.00 Prize"},
            {address : "0xf3eab2365c8689d7d429e9cf03c78b29f07907b1", reward : "$ 10.00 Prize"},
            {address : "0x825af61919cf7dcc207317d71c9fcdc1f8bb2a9e", reward : "$ 10.00 Prize"},
            {address : "0xdf0e59ecc62c184d2f2f00a93d2249aa20bf3df1", reward : "$ 10.00 Prize"},
            {address : "0x5b020553498114843383f6df68c27ca19ba0d6bd", reward : "$ 10.00 Prize"},
            {address : "0x3d127774339a9db809a0bea58526b1c00a744ebd", reward : "$ 10.00 Prize"},
            {address : "0x9572aba810b4e39e1440c0d93ae4b6479a7343b0", reward : "$ 10.00 Prize"}
        ],[
            {address : "0x00fbab65d200e11164c67c6df8f68af2472dc34e", reward : "30 $GAFI"},
            {address : "0x47d3c199093e2adea1c46726a663e1f65a8838ae", reward : "21.52 $GAFI"},
            {address : "0x91c5029ca9a4e5c5687692520f7a7b130b619201", reward : "15 $GAFI"},
            {address : "0x78fff3d4ae1210809d1c03d71aea16057c3b119d", reward : "14 $GAFI"},
            {address : "0xdc8d28503eee6fe5a1ec51f4cf55d9b2633c359e", reward : "14 $GAFI"},
            {address : "0x9441dee9a7fe3514ab73703b9a0c7f88a69f8287", reward : "14 $GAFI"},
            {address : "0x1bd36c6d6c0a0635af9e37ecdcaefa5df3efd0d2", reward : "14 $GAFI"},
            {address : "0xbfa0e64fafcf5e4ba66d9d4fc20cead1fb921079", reward : "14 $GAFI"},
            {address : "0x97150eae436a0ee3b834bc96cb9d11bab635cefb", reward : "14 $GAFI"},
            {address : "0x7e1515e2a8f65c785eea5fd7ac31349d27e53947", reward : "14 $GAFI"},
            {address : "0x94e997b3e0d37d412a4e9f2df6cebf4f32b2eca3", reward : "14 $GAFI"},
            {address : "0x3506e5f79754e8aad83152b8e6189b6c5508aad4", reward : "14 $GAFI"},
            {address : "0x825af61919cf7dcc207317d71c9fcdc1f8bb2a9e", reward : "14 $GAFI"},
        ]
    ]
    const [pageCount, setPageCount] = useState(Math.ceil(winners[currentDrawWinner - 1].length/10));
    const [filterItem, setFilterItem] = useState(winners[currentDrawWinner - 1]);
    const handlePageClick = (event:any) => {
        const newOffset = (event.selected * 10) % winners[currentDrawWinner - 1].length;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        // @ts-ignore
        setPageItem(filterItem.slice(itemOffset, itemOffset + 10))
    }, [itemOffset, filterItem])

    const onChangeDrawWiner = (draw:number) => {
        if (draw === currentDrawWinner || draw > 4) {
            return
        }
        setCurrentDrawWinner(draw)
        // @ts-ignore
        setFilterItem(winners[draw - 1 ])
        setPageCount(Math.ceil(winners[draw - 1].length/10))
        setItemOffset(0)
    }

    const onChangeSearch = (event:any) => {
        if(!event.target.value) {
            // @ts-ignore
            setFilterItem(winners[currentDrawWinner - 1 ])
            setPageCount(Math.ceil(winners[currentDrawWinner - 1].length/10))
            setItemOffset(0)
            return
        }
        const searchValue = event.target.value.toLowerCase()
        // @ts-ignore
        let result = []
        winners[currentDrawWinner - 1].map(winner => {
            if (winner.address.toLowerCase().includes(searchValue)) {
                // @ts-ignore
                result.push(winner)
            }
        })
        // @ts-ignore
        setFilterItem(result)
        setPageCount(Math.ceil(result.length/10))
        setItemOffset(0)
    }

    return (
        <div className={styles.winnerContainer}>
            <h2>🔥 WINNER 🔥</h2>
            <div className={styles.drawList}>
                <div className={currentDrawWinner === 1 ? styles.drawItemActive : styles.drawItem} onClick={() => {onChangeDrawWiner(1)}}>1st DRAW</div>
                <div className={currentDrawWinner === 2 ? styles.drawItemActive : styles.drawItem} onClick={() => {onChangeDrawWiner(2)}}>2nd DRAW</div>
                <div className={currentDrawWinner === 3 ? styles.drawItemActive : styles.drawItem} onClick={() => {onChangeDrawWiner(3)}}>3rd DRAW</div>
                <div className={currentDrawWinner === 4 ? styles.drawItemActive : styles.drawItem} onClick={() => {onChangeDrawWiner(4)}}>GAFI DRAW</div>
            </div>
            <div className={styles.winnerHeader}>
            <div className={'header'}>{ currentDrawWinner === 1 && '1st' }{ currentDrawWinner === 2 && '2nd' }{ currentDrawWinner === 3 && '3rd' }{ currentDrawWinner === 4 && 'GAFI' } Draw Winner</div>
                <div className={'search'}>
                    <span style={{fontSize: 16}} >Search </span>
                    <input
                        onChange={onChangeSearch}
                        className={styles.searchInput}
                        placeholder={'Enter your Wallet Address'}
                    />
                </div>
            </div>
            <div className={styles.winnerTable}>
                {
                    pageItem.map((winner, i) => {
                        // @ts-ignore
                        return (<div className={i % 2 === 0 ? 'cell left': 'cell right'}><span className={'address'}>{ `${winner?.address.slice(0,14)}*********${winner?.address.slice(-14)}` }</span><span className={'reward'}>{ winner?.reward }</span></div>)
                    })
                }
            </div>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageCount={pageCount}
                pageRangeDisplayed={5}
                previousLabel="<"
                renderOnZeroPageCount={null}
            />
        </div>
    )
}

export default withRouter(Winner);
