import React from "react";
import Countdown from "../Countdown";
import useStyles from "./style";
const workingImg = "/images/icons/group-working.png";

type CountDownProps = {};

const CountdownBottom: React.FC<CountDownProps> = () => {
  const styles = useStyles();

  return (
    <div className={styles.countdownBottom}>
      {/* <img src={workingImg} alt="" /> */}
      <div className="display">
        Celebrating 3rd birthday party with Icetea Labs
      </div>
      <Countdown isBottom={true} />
    </div>
  );
};

export default CountdownBottom;
