import React, { useEffect, useRef, useState } from "react";
import useStyles from "./style";
import CountdownWhite from "../../components/Base/CountdownWhite";
import Countdown from "../../components/Base/Countdown";
import CountdownEvent from "../../components/Base/CountdownEvent";


const BurnBanner = (props: any) => {
  const styles = useStyles();
  const banner = React.createRef();
  const [show, setShow] = useState(true);
  const hide = () => {
    setShow(false)
  }
  const handleClickOutside = (event:any) => {
    // @ts-ignore
    if (banner && !banner?.current?.contains(event.target)) {
      setShow(false)
    }
  }
  if (show) {
    // @ts-ignore
    return (<div className={styles.bannerOverlay} onClick={handleClickOutside}><div className={styles.bannerContainer} ref={banner}>
            <div className={styles.bannerClose} onClick={hide}>
              <span>x</span>
            </div>
            <div className={styles.bannerSubtitle}>HAPPY BURN DAY!</div>
            <div className={styles.bannerCountDown}>
              {/*<CountdownEvent startDate={new Date(1635765060000)}/>*/}
                <span>01 November 2021</span>
            </div>
            <div className={styles.bannerTitle}>34,400 $GAFI<br/>🔥BURNT🔥</div>
          </div>
        </div>
    );
  } else {
    return <div/>
  }
};

export default BurnBanner;
