import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    countDown: {
      margin: "0 auto",

      "& .countdownPart": {
        display: "inline-block",
        listStyleType: "none",
        padding: "0px 20px",
        font: "normal normal bold 40px/48px DM Sans",
        color: "#00004F",

        "& span": {
          display: "block",
          textAlign: "center",
          color: "#04047F",
        },

        "&.number": {
          display: "block",
          minWidth: 60,
          fontWeight: "bold",
          fontSize: 48,
          lineHeight: "56px",
        },
      },

      "& .listCountDown": {
        display: "flex",
        alignItems: "flex-start",

        [theme?.breakpoints?.down("sm")]: {
          textAlign: "center",
          justifyContent: "center",
        },
      },

      "& .countdownInfo": {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "18px",
        color: "#00004F !important",
      },

      [theme.breakpoints.down("xs")]: {
        "& .countdownPart": {
          padding: "10px 5px",

          "&.number": {
            padding: "5px 5px 15px 5px",
          },
        },
      },
    },
    
    countdownBottom: {
      margin: "0 auto",
      paddingBottom: 100,
      "& .countdownPart": {
        display: "inline-block",
        listStyleType: "none",
        padding: "0px 20px",
        font: "normal normal bold 55px/48px DM Sans",
        color: "#00C7F4",

        "& span": {
          display: "block",
          textAlign: "center",
          color: "#00C7F4",
        },

        "&.number": {
          display: "block",
          minWidth: 60,
          fontWeight: "bold",
          fontSize: 64,
          lineHeight: "56px",
        },
      },

      "& .listCountDown": {
        display: "flex",
        alignItems: "flex-start",

        [theme?.breakpoints?.down("sm")]: {
          textAlign: "center",
          justifyContent: "center",
        },
      },

      "& .countdownInfo": {
        fontWeight: "normal",
        fontFamily: "DM Sans",
        fontSize: 16,
        lineHeight: "18px",
        color: "#00C7F4",
      },

      [theme.breakpoints.down("xs")]: {
        "& .countdownPart": {
          padding: "10px 5px",

          "&.number": {
            padding: "5px 5px 15px 5px",
          },
        },
      },
    },
  };
});

export default useStyles;
