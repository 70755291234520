import { makeStyles } from "@material-ui/core";
const bg = "/images/bg-airdrop.png";
const oval = "/images/oval-drawns.svg";
const ovalYoutube = "/images/icons/oval-youtube.svg";
const ovalMedium = "/images/icons/oval-medium.svg";
const ovalTwitter = "/images/icons/oval-twitter.svg";
const bgGamefi = "/images/bg-gamefi-tickets.png";
const gafi = "/images/bg-ticket-gafi.png";
const bgWhoJoin = "/images/bg-join-airdrop.png";
const bgTime = "/images/bg-time.svg";

const useStyles = makeStyles((theme: any) => {
  return {
    mainLayout: {
      // gridArea: 'main',
      // width: '100%',
      display: "felx",
      flexDirection: "column",
      margin: "0 auto",
      // width: "1440px",
      // maxWidth: "calc(100vw - 240px)",
      // [theme.breakpoints.only("md")]: {
      //   maxWidth: "calc(100vw - 160px)",
      // },
      // [theme.breakpoints.down("sm")]: {
      //   maxWidth: "100vw",
      // },
    },
    eventLayout: {
      backgroundColor: "#020618",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      color: "#fff",
      paddingTop: 68,
      width: "100%",
      background: `url(${bg})`,
      backgroundRepeat: "no-repeat",
      // backgroundSize: "100%",
      backgroundPosition: "top",
      backgroundColor: "#020618",
      // backgroundPositionY: "75px",
    },

    competation: {
      "& .text-title": {
        font: "normal normal 600 40px/60px Poppins",
      },
      "& .step-1": {
        font: "normal normal normal 18px/28px Roboto-Regular",
      },
      "& .step-2": {
        font: "normal normal normal 18px/34px Roboto-Regular",
      },
      "& .gleam-button": {
        width: 256,
      },
    },

    airdropPrize: {
      padding: "80px 0",

      "& .about": {
        // background: `url(${gafi})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "auto",
        // backgroundPosition: "right",
        // backgroundPositionY: "25px",
        padding: "20px 28px",
        backgroundColor: "#00007B",
        borderRadius: 4,
        display: "flex",
        flexDirection: "column",
        // height: 180,

        "& .content": {
          font: "normal normal 600 24px/32px Poppins",
          maxWidth: "60%",
          marginTop: 12,
        },
      },
      "& .special": {
        width: 140,
        borderRadius: 4,
        background: "#51F541",
        color: "#000",
        font: "normal normal bold 12px/18px Poppins",
        letterSpacing: 1,
        alignItems: "center",
        padding: "5px 7px 3px 7px",
      },

      "& .prize-rank": {
        color: "#fff",
        font: "normal normal 500 18px/24px Poppins",
        whiteSpace: "pre",
        // position: "relative",
      },

      "& .prize-money": {
        color: "#51F541",
        font: "normal normal 600 36px/44px Poppins",
      },
    },

    socialsPrize: {
      display: "flex",
      marginTop: 8,

      "& .prize-left": {
        width: "50%",
        height: 124,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: 24,
        background: `url(${ovalTwitter})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPositionX: "150px",
        backgroundPositionY: "26px",
        backgroundColor: "#222228",
        borderRadius: 4,
      },

      "& .prize-right": {
        width: "50%",
        height: 124,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: 24,
        marginLeft: 8,
        backgroundColor: "#222228",
        borderRadius: 4,
        background: `url(${ovalMedium})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPositionX: "162px",
        backgroundPositionY: "26px",
      },
    },

    gleam: {
      width: "70%",
      maxWidth: 1440,
      margin: "0 auto",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      marginTop: 20,
      gap: "25px",

      "& .gleam-left": {
        maxWidth: 550,
        margin: "0 auto",
        "& h1": {
          font: "normal normal 600 32px/48px Poppins",
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: 40,
        },
      },
      "& .gleam-youtube": {
        height: 160,
        display: "flex",
        flexDirection: "column",
        padding: 25,
        backgroundColor: "#222228",
        borderRadius: 4,

        "& .img-youtube": {
          position: "relative",
          objectFit: "contain",
          margin: "0 20px 0 auto",
          top: -220,
        },
      },
      "& .gleam-medium": {
        height: 124,
        display: "flex",
        flexDirection: "column",
        marginTop: 8,
        padding: 25,
        backgroundColor: "#222228",
        borderRadius: 4,
        background: `url(${ovalMedium})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPositionX: "167px",
        backgroundPositionY: "27px",

        "& .img-medium": {
          position: "relative",
          objectFit: "contain",
          top: -104,
          right: -100,
        },
      },
      "& .for-socials": {
        display: "flex",
        alignItems: "center",
        marginBottom: 6,
        "& img": {
          marginRight: 8,
          marginLeft: 0,
        },
      },
      "& .bg-youtbe": {
        background: `url(${ovalYoutube})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPositionX: "150px",
        backgroundPositionY: "6px",
      },

      "& .text-prize": {
        font: "normal normal 500 18px/24px Poppins",
      },
      "& .prize-number": {
        color: "#fff",
        font: "normal normal 600 36px/44px Poppins",
        textTransform: "uppercase",
        marginRight: 6,
      },

      "& .special": {
        width: 140,
        height: 26,
        borderRadius: 4,
        background: "#3232DC",
        color: "#fff",
        font: "normal normal bold 12px/18px Poppins",
        letterSpacing: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 9,
      },

      "& .img-part2": {
        margin: "-150px auto 0",
      },

      [theme.breakpoints.down("md")]: {
        display: "grid",
        gridTemplateColumns: "1fr",
        // margin: "0 120px 120px",
        gap: "25px",
        "& img": {
          margin: "0 auto",
        },
        "& .img-part2": {
          marginTop: 0,
        },
      },
    },

    cardEvent: {
      backgroundColor: "#010134",
      background: `url(${bgGamefi})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 63%",
      display: "flex",
      flexDirection: "column",

      "& .discover": {
        background: "#51F541",
        borderRadius: 2,
        width: 191,
        height: 54,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        font: "normal normal 500 18px/24px Poppins",
        color: "#000",
      },
    },
    cardEventTitle: {
      // maxWidth: 1440,
      margin: "75px auto 0",
      width: "70%",
      maxWidth: 1440,
      "& span": {
        font: "normal normal normal 120px/100px Android-101",
      },
      "& p": {
        font: "normal normal 600 20px/32px Poppins",
        margin: "15px 0",
      },
    },

    cardEventDetail: {
      display: "flex",
      width: "70%",
      maxWidth: 1440,
      margin: "10px auto 0",

      "& .about": {
        width: "50%",
        background: `url(${gafi})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPosition: "center",
        backgroundPositionX: "260px",
        padding: "20px 0",
        borderRadius: 4,
        display: "flex",
        flexDirection: "column",
        borderRight: "1px solid rgba(255, 255, 255, 0.2)",
        // height: 180,

        "& .content": {
          font: "normal normal 600 24px/32px Poppins",
          maxWidth: "42%",
          marginTop: 12,
        },
        "& .special": {
          width: 140,
          height: 26,
          borderRadius: 4,
          background: "#00007B",
          color: "#fff",
          font: "normal normal bold 12px/18px Poppins",
          letterSpacing: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },

    gafiPrize: {
      display: "flex",
      flexDirection: "column",
      width: "50%",

      "& .prize-top": {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "30px 80px 20px",
        background: `url(${oval})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPositionX: "78px",
        backgroundPositionY: "30px",
        borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
      },

      "& .prize-bot": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "20px 80px",
      },
      "& .text-prize": {
        font: "normal normal 500 18px/24px Poppins",
      },
      "& .prize-number": {
        color: "#51F541",
        font: "normal normal 600 36px/44px Poppins",
        marginRight: 6,
      },
      "& .prize-currency": {
        color: "#51F541",
        font: "normal normal 600 24px/36px Poppins",
      },
    },

    joinEvent: {
      width: 160,
      height: 40,
      background: "#F1BA40",
      borderRadius: 2,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      color: "#000",
      font: "normal normal 500 16px/20px Poppins",
    },

    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },
    banner: {
      width: "70%",
      display: "flex",
      flexDirection: "column",
      padding: "40px 0",
      margin: "0 auto",
      maxWidth: "1440px",

      "& .text-banner": {
        display: "flex",
        flexDirection: "column",

        "& span": {
          fontFamily: "Android-101",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "168px",
          lineHeight: "193px",
        },

        "& .text-airdrop": {
          marginTop: "-40px",
        },
      },

      "& .text-detail": {
        // margin: "0 8% 0 auto",
        maxWidth: "510px",
        font: "normal normal 600 18px/30px Poppins",
      },
    },

    groupJoin: {
      display: "flex",
      //gridTemplateColumns: "1fr 1fr",
      // marginLeft: -240,
      // marginRight: -240,
      [theme.breakpoints.only("md")]: {
        marginLeft: -160,
        marginRight: -160,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0,
      },
      // margin: "120px max(120px, 50% - 660px)",
      // gap: "25px",

      [theme.breakpoints.down("md")]: {
        display: "grid",
        gridTemplateColumns: "1fr",
        margin: "0",
        gap: "25px",
      },
    },

    whoJoin: {
      width: "100%",
      // maxWidth: "720px",
      //maxWidth: "720px",
      // padding: "0 80px",
      display: "flex",
      backgroundColor: "#000057",
      // margin: "0 auto",

      "& .join-inside": {
        maxWidth: "720px",
        marginLeft: "auto",
        padding: "60px 80px",
        background: `url(${bgWhoJoin})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },

      "& .special": {
        width: 180,
        borderRadius: 4,
        background: "#F1BA40",
        color: "#00004F",
        font: "normal normal bold 14px/21px Poppins",
        letterSpacing: 1,
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "6px 0",
      },

      "& .text-join": {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontStyle: "normal",
        maxWidth: 350,
      },
      "& h1": {
        fontSize: 40,
        lineHeight: "48px",
      },
      "& h2": {
        fontSize: 28,
        lineHeight: "36px",
      },
      "& span": {
        font: "normal normal 500 18px/24px Poppins",
      },
      "& .join-time": {
        marginTop: 40,
        display: "flex",
        alignItems: "center",
        textAlign: "center",
      },
    },

    howJoin: {
      width: "100%",
      height: "100%",
      // maxWidth: "720px",
      //height: "100%",
      // paddingRight: 100,
      background: "#F1BA40",
      display: "flex",
      margin: "0 auto",

      "& .special": {
        width: 180,
        borderRadius: 4,
        background: "#010142",
        color: "#fff",
        font: "normal normal bold 14px/21px Poppins",
        letterSpacing: 1,
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "6px 0",
        marginBottom: 37,
      },

      "& .join-guide": {
        display: "flex",
        flexDirection: "column",
        whiteSpace: "pre-line",
        font: "normal normal normal 18px/28px Roboto-Regular",
        color: "#00004F",
      },

      "& .view-details": {
        font: "normal normal 500 18px/24px Poppins",
        textDecorationLine: "underline",
        color: "#00004F",
      },

      "& a": {
        display: "flex",
        alignItems: "center",
        marginTop: 43,
      },
    },

    footer: {
      gridArea: "footer",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#020618",

      padding: 0,
      [theme.breakpoints.down("xs")]: {
        padding: "0px",
      },
      position: "relative",
    },
    copyRight: {
      marginTop: "50px",
      width: "100%",
      padding: "17px 0",
      textAlign: "center",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      backgroundColor: "#D01F36",
      lineHeight: "24px",
      color: "#fff",
    },
  };
});

export default useStyles;
