import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    footer: {
      gridArea: "footer",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      padding: 0,
      [theme.breakpoints.down("xs")]: {
        padding: "0px",
      },
      position: "relative",
    },
    copyRight: {
      marginTop: "50px",
      width: "100%",
      padding: "17px 0",
      textAlign: "center",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      backgroundColor: "#EB4A70",
      lineHeight: "24px",
      color: "#fff",
    },
  };
});

export default useStyles;
