import CountdownBottom from "../CountdownBottom";
import useStyles from "./styles";

const FooterLandingLayout = () => {
  const styles = useStyles();

  return (
    <div className={styles.footer}>
      <CountdownBottom />
      
      <p className={styles.copyRight}>© Icetea Labs, 2021</p>
    </div>
  );
};

export default FooterLandingLayout;
