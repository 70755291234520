import { makeStyles } from "@material-ui/core";
const bg = "/images/bg-event-banner.svg";
const bgVideo = "/images/bg-video.svg";

const useStyles = makeStyles((theme: any) => {
  return {
    mainLayout: {
      // gridArea: 'main',
      // width: '100%',
      display: "felx",
      flexDirection: "column",
      margin: "0 auto",
      // width: "1440px",
      // maxWidth: "calc(100vw - 240px)",
      // [theme.breakpoints.only("md")]: {
      //   maxWidth: "calc(100vw - 160px)",
      // },
      // [theme.breakpoints.down("sm")]: {
      //   maxWidth: "100vw",
      // },
    },
    eventLayout: {
      backgroundColor: "#00004F",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      color: "#fff",
      paddingTop: 68,
      width: "100%",
      background: `url(${bg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "90%",
      backgroundPosition: "top",
      backgroundPositionY: "75px",

      "& .youtube": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#00007B",
        padding: 100,
        background: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "90%",
        backgroundPosition: "center",

        "& .from": {
          marginBottom: 50,
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "42px",
          lineHeight: "42px",
        },

        "& .media": {
          width: 800,
          height: 450,
          backgroundColor: "lightblue",
        },
      },

      "& .event": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 100,

        "& .title": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "42px",
          lineHeight: "42px",
          marginBottom: 24,
        },
        "& .description": {
          maxWidth: 750,
          textAlign: "center",
          fontFamily: "Roboto-Regular",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "18px",
          lineHeight: "28px",
        },

        "& .listCard": {
          display: "flex",
          marginTop: 32,
        },
      },
    },

    listCard: {
      marginTop: 32,
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "25px",
      "& a": {
        display: "flex",
        width: "100%",
      },
      "& img": {
        width: "100%",
        height: "auto",
      },

      [theme.breakpoints.only("sm")]: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: "25px",
      },
    },

    card: {
      width: "540px",
      height: "345px",
      padding: "0 20px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",

      "& .card-title": {
        fontFamily: "Android-101",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "68px",
        lineHeight: "68px",
      },

      "& .chance": {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "4px",
        marginBottom: "8px",
      },

      "& .line-1": {
        "& .rank-1": {
          display: "flex",
          marginTop: 16,
          alignItems: "center",

          "& span": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "14px",
            height: "14px",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          },
        },
        "& .special": {
          width: 100,
          borderRadius: 4,
          background: "#F1BA40",
          color: "#000",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "8px",
          lineHeight: "12px",
          letterSpacing: 1,
          alignItems: "center",
          marginLeft: 6,
          padding: "4px 4px 3px 7px",
        },

        "& .money": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "24px",
          lineHeight: "32px",
          marginLeft: 6,
        },
      },

      "& .line-2": {
        display: "flex",
        width: 210,
        marginTop: 9,
        borderTop: "1px solid rgba(255, 255, 255, 0.2)",
        borderBottom: "1px solid rgba(255, 255, 255, 0.2)",

        "& .prize": {
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: "6px 20px 6px",
          borderLeft: "1px solid rgba(255, 255, 255, 0.2)",

          "& .rank": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "16px",
            marginBottom: 2,
          },
          "& .money": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "28px",
          },
        },
        "& .prize:first-child": {
          paddingLeft: 6,
          borderLeft: 0,
        },
      },

      "& .discover": {
        borderRadius: 2,
        width: 120,
        color: "#000",
        padding: "4px 30px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
      },
    },

    socialFeeds: {
      paddingLeft: "160px",
      paddingBottom: 80,
      color: "#0505DD",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#F1BA40",

      "& .top": {
        display: "flex",
        marginBottom: 95,
        "& .social-icons": {
          marginTop: "auto",
          marginBottom: 8,
          "& a": {
            marginRight: 16,
          },
        },
      },

      "& .img-banner": {
        marginLeft: "auto",
        marginBottom: "auto",
      },

      "& .about": {
        display: "flex",
        flexDirection: "column",

        "& span": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "42px",
          lineHeight: "42px",
          marginTop: 84,
          marginBottom: 23,
        },

        "& .description": {
          maxWidth: "450px",
          fontFamily: "Roboto-Regular",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "18px",
          lineHeight: "28px",
        },
      },

      "& .img-feeds": {
        objectFit: "contain",
        left: 0,
      },
    },
    listTweets: {
      display: "flex",
      flexWrap: "wrap",

      "& .tweetItems": {
        flex: "30%",
        maxWidth: 400,
        marginBottom: 20,
        marginRight: "auto",
      },
    },

    banner: {
      width: "100%",
      display: "flex",
      flexDirection: "column",

      "& .timing": {
        marginTop: 88,
        display: "flex",
        alignItems: "stretch",
        paddingRight: 120,
        maxHeight: 128,

        "& img": {
          objectFit: "contain",
          position: "relative",
        },

        "& .countdown": {
          backgroundColor: "#00C7F4",
          display: "flex",
          alignItems: "center",
          marginLeft: "-65px",
          paddingLeft: "65px",
        },

        "& .date": {
          color: "#FFD900",
          fontWeight: "bold",
          fontFamily: "Poppins",
          fontSize: "32px",
          margin: "auto",
          marginRight: 0,
        },
      },

      "& .top-banner": {
        display: "flex",
        flexDirection: "column",
        margin: "70px auto 0",
        maxWidth: "1440px",
        width: "70%",
      },

      "& .top": {
        display: "flex",
        alignItems: "center",
        marginBottom: 23,

        "& .title": {
          maxWidth: "655px",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "66px",
          lineHeight: "88px",
          textTransform: "uppercase",
        },

        "& .video": {
          fontFamily: "Poppins",
          display: "flex",
          alignItems: "center",
          marginLeft: "auto",
          fontWeight: "bold",
          fontSize: "28px",
          lineHeight: "28px",
          cursor: "pointer",

          "& img": {
            width: 80,
            marginRight: 20,
          },

          "& span": {
          },
        },
      },

      "& .text": {
        fontFamily: "Android-101",
        fontWeight: "600",
        fontSize: "230px",
        lineHeight: "187px",
      },
    },
  };
});

export default useStyles;
