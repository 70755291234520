import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    navBar: {
      gridArea: "header",
      height: 80,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "0 auto",
      width: "1440px",
      maxWidth: "calc(100vw - 240px)",
      padding: '0 120px',
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.only("md")]: {
        maxWidth: "calc(100vw - 160px)",
      },
      [theme.breakpoints.down("sm")]: {
        // flexDirection: "column",
        margin: "10px auto",
        maxWidth: "calc(100vw - 60px)",
        padding: '0'
      },
    },
    rightHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      // "& span": {
      //   color: "#fff",
      //   fontFamily: "Poppins",
      //   fontStyle: "normal",
      //   fontWeight: "600",
      //   fontSize: "18px",
      //   lineHeight: "24px",
      //   marginLeft: 11,
      // },
    },

    btn: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      textTransform: "uppercase",
      fontSize: "14px",
      lineHeight: "16px",
      // letterSpacing: "1px",
      color: "#FFFFFF",
      mixBlendMode: "normal",
      backgroundColor: "none",
      border: "none",
      cursor: "pointer",

      "&:focus": {
        outline: "none",
      },
      "&.my-account": {
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
        marginLeft: 10,
        font: "normal normal bold 14px/18px DM Sans",

        "& img": {
          marginRight: 4,
        },

        "& .icon": {
          width: 20,
          filter: "brightness(0) invert(1)",
        },

        "& span": {
          display: "flex",
          alignItems: "center",
        },

        "&:hover": {
          color: "white",
        },

        [theme.breakpoints.only("xs")]: {
          marginTop: 0,
          marginBottom: 20,
        },
      },
    },
  };
});

export default useStyles;
